// React imports
import React from 'react';

// UI – style imports
import { WithStyles, withStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';

// local imports
import { styles } from './Efective.style';
import recibirGif from 'images/gifs/app-steps-enviar-recibir-1248px.gif';

function Efective(props: WithStyles<typeof styles>) {
    const { classes } = props;

    return (
        <Box className={classes.root}>
            <Box className={classes.gif}>
                <img src={recibirGif} alt="Pasos para recibir y enviar Cuenca" />
            </Box>

            <Box className={classes.textContainer}>
                <Box id="titleContainer">
                    <p className="title">UNA</p>
                    <p className="title" id="bot">
                        ALTERNATIVA
                    </p>
                    <p id="sub">al efectivo</p>
                </Box>

                <Box id="text">
                    <p>
                        <em>Tap</em> y transfiere, <em>tap</em> y paga, <em>tap</em> y recibe dinero. A través de tu app
                        Cuenca, la administración de tu cuenta y recursos, y consulta de saldos y movimientos, está al
                        alcance de la mano, todos los días, a todas horas, en cualquier lugar.
                    </p>
                    <p>
                        Recuerda que cada vez que abres tu app, inicias sesión; después de 5 minutos de inactividad, la
                        sesión se cierra automáticamente. Inicia sesión nuevamente con tu contraseña.
                    </p>
                </Box>
            </Box>
        </Box>
    );
}

export default withStyles(styles)(Efective);
