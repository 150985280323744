import { Theme, createStyles } from '@material-ui/core/styles';

export const styles = (theme: Theme) =>
    createStyles({
        root: {
            fontFamily: 'MuseoSans, sans-serif',
            width: '88%', // 1222
            marginBottom: '368px',
            [theme.breakpoints.up(1441)]: { maxWidth: '1222px' },
            [theme.breakpoints.down(1000)]: { width: '100%' },
            [theme.breakpoints.down(600)]: { marginBottom: '250px' },
            [theme.breakpoints.down(501)]: { marginBottom: '163px' },
        },
        titleContainer: {
            width: '36.1%',
            marginBottom: '80px',
            marginLeft: '33%',
            '& #top': {
                fontSize: '32.07px',
                fontWeight: 500,
                color: '#3D4274',
                letterSpacing: '2px',
                textAlign: 'left',
                marginBottom: '0',
            },
            '& .title': {
                fontSize: '59.57px',
                fontWeight: 900,
                color: '#3D4274',
                letterSpacing: '7px',
                textAlign: 'left',
                marginBottom: '0',
            },
            '& #snd': { marginLeft: '105px' },
            '& #trd': { marginLeft: '225px' },
            [theme.breakpoints.down(1000)]: {
                width: '41.67%',
                marginBottom: '85px',
                marginLeft: '22.27%',
                '& #top': {
                    fontSize: '29.7px',
                    letterSpacing: '2.11px',
                },
                '& .title': {
                    fontSize: '55.24px',
                    letterSpacing: '6.34px',
                },
            },
            [theme.breakpoints.down(701)]: {
                width: '43%',
                marginLeft: '21.86%',
                '& #top': {
                    fontSize: '28.13px',
                    letterSpacing: '2px',
                },
                '& .title': {
                    fontSize: '52.24px',
                    letterSpacing: '6px',
                },
            },
            [theme.breakpoints.down(650)]: {
                marginLeft: '18%',
                '& #top': {
                    fontSize: '25px',
                    letterSpacing: '1.8px',
                },
                '& .title': {
                    fontSize: '45px',
                    letterSpacing: '5.5px',
                },
            },
            [theme.breakpoints.down(501)]: {
                marginLeft: '22.28%',
                '& #top': {
                    fontSize: '16.07px',
                    letterSpacing: '1.14px',
                },
                '& .title': {
                    fontSize: '29.85px',
                    letterSpacing: '3.43px',
                },
                '& #snd': { marginLeft: '50px' },
                '& #trd': { marginLeft: '112px' },
            },
        },
        content: {
            display: 'flex',
            flexDirection: 'row',
            '& #text': {
                width: '25.5%',
                marginLeft: '203px',
                fontSize: '18.33px',
                fontWeight: 500,
                color: '#3D4274',
                letterSpacing: '0.75px',
                lineHeight: '26.35px',
            },
            [theme.breakpoints.down(1200)]: {
                '& #text': {
                    width: '40%',
                    marginLeft: '100px',
                },
            },
            [theme.breakpoints.down(1000)]: {
                flexDirection: 'column',
                '& #text': {
                    width: '56%',
                    marginLeft: '22.27%',
                },
            },
            [theme.breakpoints.down(769)]: {
                '& #text': {
                    fontSize: '21px',
                    letterSpacing: '0.75px',
                    lineHeight: '30px',
                },
            },
            [theme.breakpoints.down(701)]: {
                '& #text': {
                    width: '56.43%',
                    marginLeft: '21.86%',
                    fontSize: '19px',
                    letterSpacing: '0.34px',
                    lineHeight: '28px',
                },
            },
            [theme.breakpoints.down(650)]: {
                '& #text': {
                    width: '65%',
                    marginLeft: '18%',
                    fontSize: '16px',
                    letterSpacing: '0.5px',
                    lineHeight: '26px',
                },
            },
            [theme.breakpoints.down(501)]: {
                '& #text': {
                    width: '52%',
                    marginLeft: '22.28%',
                    fontSize: '10.86px',
                    letterSpacing: '0.42px',
                    lineHeight: '16px',
                },
            },
        },
        image: {
            width: '57.69%',
            [theme.breakpoints.down(1000)]: {
                width: '100%',
                textAlign: 'center',
                marginBottom: '68px',
            },
            [theme.breakpoints.down(501)]: { marginBottom: '42px' },
        },
    });
