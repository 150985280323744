import { Theme, createStyles } from '@material-ui/core/styles';

export const styles = (theme: Theme) =>
    createStyles({
        root: {
            position: 'absolute',
            height: '100%',
            width: '100%',
            zIndex: theme.zIndex.speedDial,
        },
        quote: {
            fontSize: '41.4px',
            fontWeight: 500,
            color: '#3D4274',
            letterSpacing: '1.8px',
            lineHeight: '52.7px',
            height: '332px',
            marginBottom: '51px',
            [theme.breakpoints.down(1000)]: {
                fontSize: '32px',
                letterSpacing: '1.07px',
                lineHeight: '47px',
            },
            [theme.breakpoints.down(850)]: { marginBottom: '105px' },
            [theme.breakpoints.down(701)]: {
                fontSize: '30px',
                letterSpacing: '1px',
                lineHeight: '45px',
                marginBottom: '100px',
            },
            [theme.breakpoints.down(650)]: {
                fontSize: '28px',
                letterSpacing: '0.85px',
                lineHeight: '43px',
            },
            [theme.breakpoints.down(501)]: {
                fontSize: '17.14px',
                letterSpacing: '0.57px',
                lineHeight: '25.71px',
                height: '260px',
                marginBottom: '10px',
            },
        },
        logo: {
            float: 'right',
            '& img': { maxHeight: '96px' },
            [theme.breakpoints.down(850)]: {
                float: 'none',
                '& img': { maxHeight: '72px' },
            },
        },
    });
