// React imports
import React from 'react';

// UI – style imports
import clsx from 'clsx';
import { WithStyles, withStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';

// local imports
import { styles } from './Manifest.style';

function Manifest(props: WithStyles<typeof styles>) {
    const { classes } = props;

    return <Box className={classes.root}></Box>;
}

export default withStyles(styles)(Manifest);
