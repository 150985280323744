import { Theme, createStyles } from '@material-ui/core/styles';

export const styles = (theme: Theme) =>
    createStyles({
        root: {
            fontFamily: 'MuseoSans, sans-serif',
            color: theme.palette.primary.main,
            marginBottom: '300px',
            marginLeft: '25%',
            width: '75%',
            '& #idContainer': {
                display: 'flex',
                flexDirection: 'row',
            },
            '& .smallMarginRight': { marginRight: '30px' },
            '& .bigMarginRight': { marginRight: '37px' },
            [theme.breakpoints.up(1441)]: {
                maxWidth: '1080px',
                margin: '0 auto 350px auto',
            },
            [theme.breakpoints.down(1310)]: { marginLeft: '15%' },
            [theme.breakpoints.down(1200)]: { marginLeft: '2%' },
            [theme.breakpoints.down(1000)]: {
                width: '62%',
                margin: 'auto',
                marginBottom: '225px',
                '& #idContainer': {
                    flexDirection: 'column',
                    marginLeft: '55px',
                },
            },
            [theme.breakpoints.down(769)]: {
                '& #idContainer': { marginLeft: '5%' },
            },
            [theme.breakpoints.down(501)]: {
                width: '60%',
                marginBottom: '140px',
                marginLeft: '22.28%',
                '& #idContainer': { marginLeft: 0 },
            },
        },
        textContainer: {
            display: 'flex',
            flexDirection: 'row',
            marginBottom: '95px',
            marginLeft: '75px',
            marginRight: '75px',
            '& #titleContainer': {
                width: '372px',
                marginLeft: '78.3px',
                marginTop: '3px',
                '& .title': {
                    fontWeight: 900,
                    fontSize: '59.57px',
                    letterSpacing: '7px',
                    marginBottom: '0',
                },
                '& #bot': { marginLeft: '27px' },
                '& #sub': {
                    fontSize: '32.07px',
                    letterSpacing: '2px',
                    marginTop: '10px',
                },
            },
            '& #explanation': {
                width: '312px',
                fontSize: '18.33px',
                fontWeight: 500,
                letterSpacing: '0.75px',
                lineHeight: '26.35px',
                marginLeft: '60px',
            },
            [theme.breakpoints.down(1200)]: {
                '& #titleContainer': {
                    width: '40%',
                },
                '& #explanation': {
                    width: '40%',
                    marginLeft: '10%',
                },
            },
            [theme.breakpoints.down(1000)]: {
                flexDirection: 'column',
                marginBottom: '50px',
                marginLeft: '55px',
                marginRight: '0',
                '& #titleContainer': {
                    width: '100%',
                    marginBottom: '76px',
                    marginLeft: '0',
                    '& .title': {
                        fontSize: '55.24px',
                        letterSpacing: '6.34px',
                    },
                    '& #bot': { marginLeft: '0 !important' },
                    '& #sub': {
                        fontSize: '29.7px',
                        letterSpacing: '2.11px',
                        marginBottom: 0,
                    },
                },
                '& #explanation': {
                    width: '100%',
                    marginLeft: '0',
                },
            },
            [theme.breakpoints.down(769)]: {
                marginLeft: '5%',
                '& #explanation': {
                    fontSize: '21px',
                    letterSpacing: '0.75px',
                    lineHeight: '30px',
                },
            },
            [theme.breakpoints.down(701)]: {
                '& #titleContainer': {
                    '& .title': {
                        fontSize: '52.24px',
                        letterSpacing: '6px',
                    },
                    '& #sub': {
                        fontSize: '28.13px',
                        letterSpacing: '2px',
                    },
                },
                '& #explanation': {
                    fontSize: '19px',
                    letterSpacing: '0.34px',
                    lineHeight: '28px',
                },
            },
            [theme.breakpoints.down(650)]: {
                '& #titleContainer': {
                    '& .title': {
                        fontSize: '45px',
                        letterSpacing: '5.5px',
                    },
                    '& #sub': {
                        fontSize: '25px',
                        letterSpacing: '1.8px',
                    },
                },
                '& #explanation': {
                    fontSize: '16px',
                    letterSpacing: '0.5px',
                    lineHeight: '26px',
                },
            },
            [theme.breakpoints.down(501)]: {
                marginLeft: 0,
                '& #titleContainer': {
                    marginBottom: '45px',
                    '& .title': {
                        fontSize: '29.85px !important',
                        letterSpacing: '3.43px !important',
                    },
                    '& #sub': {
                        fontSize: '16.07px !important',
                        letterSpacing: '1.14px !important',
                    },
                },
                '& #explanation': {
                    width: '92%',
                    fontSize: '10.86px',
                    letterSpacing: '0.45px',
                    lineHeight: '16px',
                },
            },
        },
    });
