// React imports
import React from 'react';

// UI – style imports
import { WithStyles, withStyles, useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';

// local imports
import { styles } from './LikeYou.style';
import serviciosImage from 'images/servicios-creados-para-ti.jpg';
import serviciosImageIpad from 'images/servicios-creados-para-ti-ipad.jpg';

function LikeYou(props: WithStyles<typeof styles>) {
    const { classes } = props;
    const desktop = useMediaQuery('(min-width:1000px)');

    return (
        <Box className={classes.root}>
            <Box className={classes.titleContainer}>
                <p id="top">Somos</p>
                <p className="title">GENTE</p>
                <p id="snd" className="title">
                    COMO
                </p>
                <p id="trd" className="title">
                    TÚ
                </p>
            </Box>

            <Box className={classes.content}>
                <Box className={classes.image}>
                    <img src={desktop ? serviciosImage : serviciosImageIpad} alt="Imagen equipo Cuenca" />
                </Box>
                <p id="text">
                    Somos la Fintech que emerge de la esencia heterogénea de la Ciudad de México, en ella fluyen
                    rostros, saberes y experiencias de mujeres y hombres que manifiestan su vitalidad humana en su
                    quehacer cotidiano. Los ríos de Cuenca provienen de Tabasco, Venezuela, Guadalajara, Ciudad de
                    México y Estados Unidos; y de las comunidades de Hidalgo, Oaxaca, Puebla y Querétaro. Trabajamos con
                    artesanos y artistas; creemos en la diversidad, la tolerancia y la empatía como ejes fundamentales
                    de nuestro quehacer.
                </p>
            </Box>
        </Box>
    );
}

export default withStyles(styles)(LikeYou);
