import { Theme, createStyles } from '@material-ui/core/styles';

export const styles = (theme: Theme) =>
    createStyles({
        root: {
            fontFamily: 'MuseoSans, sans-serif',
            width: '1044px',
            marginBottom: '343px',
            float: 'right',
            display: 'flex',
            flexDirection: 'row',
            [theme.breakpoints.up(1441)]: {
                width: '72.5%',
            },
            [theme.breakpoints.down(1050)]: {
                width: '100%',
            },
            [theme.breakpoints.down(1100)]: {
                width: '77.86%',
                flexDirection: 'column',
                marginBottom: '250px',
                marginLeft: '22.13%',
            },
            [theme.breakpoints.down(600)]: {
                width: '100%',
                marginBottom: '150px',
            },
        },
        textContainer: {
            width: '29.5%',
            marginRight: '200px',
            '& #text': {
                fontSize: '18.33px',
                fontWeight: 500,
                color: '#3D4274',
                letterSpacing: '0.75px',
                lineHeight: '26.35px',
            },
            [theme.breakpoints.down(1000)]: {
                width: '72%',
                marginBottom: '100px',
                marginRight: '0',
            },
            [theme.breakpoints.down(769)]: {
                '& #text': {
                    fontSize: '21px',
                    letterSpacing: '0.97px',
                    lineHeight: '30px',
                },
            },
            [theme.breakpoints.down(701)]: {
                width: '73%',
                marginBottom: '70px',
                '& #text': {
                    fontSize: '19px',
                    letterSpacing: '0.34px',
                    lineHeight: '28px',
                },
            },
            [theme.breakpoints.down(650)]: {
                marginLeft: '20%',
                '& #text': {
                    fontSize: '16px',
                    letterSpacing: '0.5px',
                    lineHeight: '26px',
                },
            },
            [theme.breakpoints.down(501)]: {
                '& #text': {
                    width: '80%',
                    fontSize: '10.86px',
                    letterSpacing: '0.45px',
                    lineHeight: '16px',
                },
            },
        },
        titleContainer: {
            width: '296px',
            marginBottom: '121px',
            '& #top': {
                fontSize: '32.07px',
                fontWeight: 500,
                color: '#3E4375',
                letterSpacing: '2px',
                marginBottom: '0',
            },
            '& .title': {
                fontSize: '59.57px',
                fontWeight: 900,
                color: '#3E4375',
                letterSpacing: '7px',
                marginBottom: '0',
            },
            '& #snd': { marginLeft: '25px' },
            '& #trd': { marginLeft: '65px' },
            [theme.breakpoints.down(1000)]: {
                width: '73.5%',
                marginBottom: '72px',
                '& #top': {
                    fontSize: '29.7px',
                    letterSpacing: '2.11px',
                },
                '& .title': {
                    fontSize: '55.24px',
                    letterSpacing: '6.34px',
                },
                '& #snd': { marginLeft: '29.7px' },
                '& #trd': { marginLeft: '145.4px' },
            },
            [theme.breakpoints.down(701)]: {
                '& #top': {
                    fontSize: '28.13px',
                    letterSpacing: '2px',
                },
                '& .title': {
                    fontSize: '52.24px',
                    letterSpacing: '6px',
                },
                '& #snd': { marginLeft: '35px' },
            },
            [theme.breakpoints.down(650)]: {
                '& #top': {
                    fontSize: '25px',
                    letterSpacing: '1.8px',
                },
                '& .title': {
                    fontSize: '45px',
                    letterSpacing: '5.5px',
                },
            },
            [theme.breakpoints.down(501)]: {
                '& #top': {
                    fontSize: '16.07px',
                    letterSpacing: '1.14px',
                },
                '& .title': {
                    fontSize: '29.85px',
                    letterSpacing: '3.43px',
                },
                '& #snd': { marginLeft: '25.7px' },
                '& #trd': { marginLeft: '51.4px' },
            },
        },
        image: {
            height: '478px',
            width: '537px',
            '& img': {
                height: '100%',
                width: '100%',
                objectFit: 'cover',
            },
            [theme.breakpoints.up(1441)]: {
                height: '100%',
                width: '100%',
            },
            [theme.breakpoints.down(1000)]: { marginLeft: 'auto' },
            [theme.breakpoints.down(701)]: {
                height: '372px',
                width: '63.43%',
            },
            [theme.breakpoints.down(600)]: { width: '100%' },
        },
        linkInsideP: {
            color: theme.palette.primary.dark,
            fontWeight: 700,
            textDecoration: 'none',
        },
    });
