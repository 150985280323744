import { Theme, createStyles } from '@material-ui/core/styles';

export const styles = (theme: Theme) =>
    createStyles({
        root: {
            fontFamily: 'MuseoSans, sans-serif',
            height: '483px',
            width: '56.8%', // 818
            maxWidth: '818px',
            marginBottom: '200px',
            marginLeft: '28.19%',
            overflow: 'hidden',
            '& #dots-press': {
                width: '210px',
                position: 'absolute',
                zIndex: theme.zIndex.appBar,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                bottom: '0',
                marginLeft: '-28px',
            },
            [theme.breakpoints.up(1441)]: {
                margin: '0 auto 200px auto',
            },
            [theme.breakpoints.down(1300)]: {
                width: '70%',
                marginLeft: '15%',
            },
            [theme.breakpoints.down(1050)]: {
                width: '85%',
                marginLeft: '10%',
            },
            [theme.breakpoints.down(850)]: {
                height: '539px',
                width: '70.7%',
                marginLeft: '22%',
                marginBottom: '120px',
                '& #dots-press': { bottom: '110px' },
            },
            [theme.breakpoints.down(501)]: {
                height: '350px',
                '& #dots-press': { bottom: '90px' },
            },
        },
    });
