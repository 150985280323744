// React imports
import React from 'react';

// UI – style imports
import { WithStyles, withStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';

// local imports
import { styles } from './Press.style';
import Carousel from 'components/Carousel';
import PressCarousel from 'components/PressCarousel';

type IState = {
    currentSlide: number;
    currentPage: number;
    timerId: number;
};

function Press(props: WithStyles<typeof styles>) {
    const { classes } = props;

    return (
        <Box className={classes.root}>
            <Carousel
                dotStyle="press"
                contentWidth={818}
                carouselSlides={[
                    style => <PressCarousel type="cnn" style={style} />,
                    style => <PressCarousel type="financiero" style={style} />,
                    style => <PressCarousel type="entrepreneur" style={style} />,
                    style => <PressCarousel type="ceo" style={style} />,
                    style => <PressCarousel type="efe" style={style} />,
                    style => <PressCarousel type="fractal" style={style} />,
                    style => <PressCarousel type="contxto" style={style} />,
                    style => <PressCarousel type="me" style={style} />,
                ]}
            />
        </Box>
    );
}

export default withStyles(styles)(Press);
