import { Theme, createStyles } from '@material-ui/core';

export const styles = (theme: Theme) =>
    createStyles({
        root: {
            fontFamily: 'MuseoSans, sans-serif',
            width: '56.8%',
            maxWidth: '820px',
            marginBottom: '102px',
            marginLeft: '28.19%',
            '& p': {
                marginTop: '40px',
                fontSize: '13px',
                color: theme.palette.primary.main,
                letterSpacing: '0.53px',
                lineHeight: '18.35px',
            },
            '& hr': {
                height: 1,
                background: theme.palette.primary.main,
            },
            [theme.breakpoints.up(1441)]: {
                margin: '0 auto 102px auto',
            },
            [theme.breakpoints.down(1300)]: {
                width: '70%',
                marginLeft: '15%',
            },
            [theme.breakpoints.down(1050)]: {
                width: '85%',
                marginLeft: '10%',
                '& p': {
                    marginTop: '35px',
                    fontSize: '9.9px',
                    letterSpacing: '0.49px',
                    lineHeight: '14px',
                },
            },
            [theme.breakpoints.down(850)]: {
                width: '70.7%',
                marginLeft: '22%',
                marginBottom: '30px',
                '& p': {
                    marginTop: '25px',
                    fontSize: '6.86px',
                    letterSpacing: '0.45px',
                    lineHeight: '9.91px',
                },
            },
        },
    });
