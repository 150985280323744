// React imports
import React, { useState, useEffect } from 'react';

// gatbsy imports
import { PageProps, graphql } from 'gatsby';

// SEO
import SEO from 'components/seo';

// Theme and layout
import WithTheme from 'components/WithTheme';
import Layout from 'views/Layout';

// Page views
import Manifest from 'views/Index/Manifest';
import CuencaTeam from 'views/Index/CuencaTeam';
import Description from 'views/Index/Description';
import Identity from 'views/Index/Identity';
import Efective from 'views/Index/Efective';
import Payments from 'views/Index/Payments';
import Deposits from 'views/Index/Deposits';
import Cards from 'views/Index/Cards';
import Support from 'views/Index/Support';
import Fees from 'views/Index/Fees';
import LikeYou from 'views/Index/LikeYou';
import Community from 'views/Index/Community';
import Investors from 'views/Index/Investors';
import OpenSource from 'views/Index/OpenSource';
import Press from 'views/Index/Press';
import MiniTerms from 'views/Index/MiniTerms';
import Hacienda from 'views/Index/Hacienda';
import Notice from 'views/Index/notice';

type DataProps = {
    site: {
        buildTime: string;
    };
};

const MainPage: React.FC<PageProps<DataProps>> = ({ data, path, location }) => {
    const [showCollage, setShowCollage] = useState(true);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const { userAgent } = window.navigator;
            setShowCollage(!/^((?!chrome|android).)*safari/i.test(userAgent));
        }
    }, []);

    return (
        <WithTheme>
            <Layout location={location}>
                <SEO title="Cuenca" />

                <Manifest />

                <Description />

                <Identity />

                <Efective />

                <Payments />

                <Deposits />

                <Cards />

                <Support />

                <Fees />

                <LikeYou />

                <Community />

                <OpenSource />

                <Investors />

                <Press />

                <MiniTerms />

                <Hacienda />

                <Notice />
            </Layout>
        </WithTheme>
    );
};

export default MainPage;

export const query = graphql`
    {
        site {
            buildTime(formatString: "YYYY-MM-DD hh:mm a z")
        }
    }
`;
