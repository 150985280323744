// React imports
import React from 'react';

// UI – style imports
import { WithStyles, withStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';

// local imports
import { styles } from './Payments.style';
import Carousel from 'components/Carousel';
import ImageCarousel from 'components/ImageCarousel';

function Payments(props: WithStyles<typeof styles>) {
    const { classes } = props;

    return (
        <Box className={classes.root}>
            <Box className={classes.carousel}>
                <Carousel
                    turnDuration={5000}
                    dotStyle="payments"
                    carouselSlides={[
                        style => <ImageCarousel type="agua" style={style} />,
                        style => <ImageCarousel type="cable" style={style} />,
                        style => <ImageCarousel type="electricidad" style={style} />,
                        style => <ImageCarousel type="gas" style={style} />,
                        style => <ImageCarousel type="internet" style={style} />,
                        style => <ImageCarousel type="movil" style={style} />,
                        style => <ImageCarousel type="tarjeta" style={style} />,
                        style => <ImageCarousel type="television" style={style} />,
                        style => <ImageCarousel type="telefono" style={style} />,
                    ]}
                />
            </Box>

            <Box className={classes.textContainer}>
                <Box id="titleContainer">
                    <p className="titleLight">Donde estés,</p>
                    <p className="title">REALIZA</p>
                    <p className="title" id="bot">
                        TUS PAGOS
                    </p>
                    <p className="titleLight">desde tu app</p>
                </Box>

                <p id="text">
                    ¿Necesitas pagar tus servicios y no tienes tiempo? ¡No rompas tu rutina! Con Cuenca lo puedes hacer
                    desde tu app, es cómodo e inmediato.
                </p>
            </Box>
        </Box>
    );
}

export default withStyles(styles)(Payments);
