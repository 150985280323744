import { Theme, createStyles } from '@material-ui/core/styles';

export const styles = (theme: Theme) =>
    createStyles({
        root: {
            fontFamily: 'MuseoSans, sans-serif',
            width: '58.5%', // 822px
            marginBottom: '338px',
            marginLeft: '27.78%',
            display: 'flex',
            flexDirection: 'row',
            [theme.breakpoints.up(1441)]: {
                maxWidth: '842.39px',
                margin: '0 auto 338px auto',
            },
            [theme.breakpoints.down(1000)]: {
                width: '70.84%',
                marginLeft: '22.14%',
            },
            [theme.breakpoints.down(701)]: {
                width: '71.57%',
                marginLeft: '21%',
            },
            [theme.breakpoints.down(600)]: {
                width: '80%',
                marginBottom: '250px',
                marginLeft: '20%',
            },
            [theme.breakpoints.down(501)]: {
                width: '77.72%',
                marginBottom: '163px',
                marginLeft: '22.28%',
            },
        },
        textContainer: {
            width: '57.54%',
            marginRight: '25.67%',
            '& #text': {
                fontSize: '18.33px',
                fontWeight: 500,
                color: '#3D4274',
                letterSpacing: '0.75px',
                lineHeight: '26.35px',
            },
            [theme.breakpoints.down(769)]: {
                width: '64%',
                marginRight: '16%',
                '& #text': {
                    fontSize: '21px',
                    letterSpacing: '0.75px',
                    lineHeight: '30px',
                },
            },
            [theme.breakpoints.down(701)]: {
                marginRight: '12%',
                '& #text': {
                    width: '92%',
                    fontSize: '19px',
                    letterSpacing: '0.34px',
                    lineHeight: '28px',
                },
            },
            [theme.breakpoints.down(650)]: {
                marginRight: '4%',
                '& #text': {
                    fontSize: '16px',
                    letterSpacing: '0.5px',
                    lineHeight: '26px',
                },
            },
            [theme.breakpoints.down(501)]: {
                width: '54%',
                marginRight: '12%',
                '& #text': {
                    width: '100%',
                    fontSize: '10.86px',
                    letterSpacing: '0.25px',
                    lineHeight: '16px',
                },
            },
        },
        titleContainer: {
            marginBottom: '120.65px',
            '& #top': {
                fontSize: '32.07px',
                fontWeight: 500,
                color: '#3D4274',
                letterSpacing: '2px',
                marginBottom: '0',
            },
            '& .title': {
                fontSize: '59.57px',
                fontWeight: 900,
                color: '#3D4274',
                letterSpacing: '7px',
                marginBottom: '0',
            },
            '& #snd': { marginLeft: '75px' },
            [theme.breakpoints.down(1000)]: {
                marginBottom: '100px',
                '& #top': {
                    fontSize: '29.7px',
                    letterSpacing: '2.11px',
                },
                '& .title': {
                    fontSize: '55.24px',
                    letterSpacing: '6.34px',
                },
            },
            [theme.breakpoints.down(701)]: {
                '& #top': {
                    fontSize: '28.13px',
                    letterSpacing: '2px',
                },
                '& .title': {
                    fontSize: '52.24px',
                    letterSpacing: '6px',
                },
            },
            [theme.breakpoints.down(650)]: {
                marginBottom: '70px',
                '& #top': {
                    fontSize: '25px',
                    letterSpacing: '1.8px',
                },
                '& .title': {
                    fontSize: '45px',
                    letterSpacing: '5.5px',
                },
            },
            [theme.breakpoints.down(501)]: {
                '& #top': {
                    fontSize: '16.07px',
                    letterSpacing: '1.14px',
                },
                '& .title': {
                    fontSize: '29.85px',
                    letterSpacing: '3.43px',
                },
                '& #snd': { marginLeft: '30px' },
            },
        },
        logoContainer: {
            width: '16.79%',
            marginTop: '185.41px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            [theme.breakpoints.down(1000)]: {
                width: '21%',
                marginTop: '35%',
            },
            [theme.breakpoints.down(769)]: { marginTop: '50%' },
            [theme.breakpoints.down(650)]: { marginTop: '60%' },
            [theme.breakpoints.down(501)]: { marginTop: '43%' },
            [theme.breakpoints.down(450)]: { marginTop: '50%' },
            [theme.breakpoints.down(400)]: { marginTop: '60%' },
        },
    });
