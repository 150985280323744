import React, { useState, useRef } from 'react';

// UI – style imports
import { WithStyles, withStyles, useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';

// local imports
import { styles } from './Video.style';

type VideoProps = {
    url: string;
    title: string;
    imagePreview?: string;
} & typeof defaultProps;

const defaultProps = {
    url: 'https://www.youtube.com/embed/5RsxI_KK6Q8?autoplay=1&loop=1&mute=1',
    title: 'Componente de Video',
};

const PLAY_ICON = (
    <SvgIcon className="controlIcon" viewBox="0 0 46 64">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="web_prueba_sketch_home_p1" transform="translate(-701.000000, -14926.000000)" fill="#FEFEFE">
                <g id="tejiendo_comunidad" transform="translate(0.000000, 13623.000000)">
                    <g id="VIDEO_TENANGO" transform="translate(0.000000, 919.777378)">
                        <path
                            d="M706.794305,384.36987 C703.870526,382.099036 701.5,383.303643 701.5,387.061819 L701.5,443.191552 C701.5,446.948733 703.98915,448.325569 707.058777,446.266786 L744.117941,421.406076 C747.187569,419.347292 747.306192,415.837006 744.382413,413.566171 L706.794305,384.36987 Z"
                            id="boton_play"
                        ></path>
                    </g>
                </g>
            </g>
        </g>
    </SvgIcon>
);

const PLAY_ICON_MOBILE = (
    <SvgIcon className="controlIcon" viewBox="-12 -16.5 46 64">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="web_mobile_sketch_030620" transform="translate(-350.000000, -21110.000000)" fill="#FEFEFE">
                <g id="Group-4" transform="translate(-14.000000, 20923.000000)">
                    <path
                        d="M366.823246,187.59165 C365.263721,186.420448 364,187.042059 364,188.979501 L364,217.921303 C364,219.858745 365.326937,220.568904 366.96399,219.506912 L386.729691,206.688623 C388.366744,205.62663 388.42996,203.816698 386.870436,202.645495 L366.823246,187.59165 Z"
                        id="Fill-2"
                    ></path>
                </g>
            </g>
        </g>
    </SvgIcon>
);

function Video(props: WithStyles<typeof styles> & VideoProps) {
    const video = useRef(null);
    const [paused, setPaused] = useState(true);
    const { classes, url, title, imagePreview } = props;

    const desktop = useMediaQuery('(min-width:1000px)');

    const togglePlay = () => {
        const method = video.current.paused ? 'play' : 'pause';
        setPaused(!paused);
        video.current[method]();
    };

    return (
        <Box className={classes.root}>
            <video
                loop
                playsInline
                preload="metadata"
                poster={imagePreview}
                ref={video}
                className={classes.video}
                title={title}
                onClick={togglePlay}
            >
                <source src={url} type="video/mp4" />
            </video>

            {paused ? (
                <div className={classes.controls}>
                    <IconButton
                        size={desktop ? 'medium' : 'small'}
                        className={classes.controlButton}
                        onClick={togglePlay}
                        aria-label={`${paused ? 'Pausar' : 'Reproducir'} video Cuenca`}
                    >
                        {desktop ? PLAY_ICON : PLAY_ICON_MOBILE}
                    </IconButton>
                </div>
            ) : null}
        </Box>
    );
}
Video.defaultProps = defaultProps;

export default withStyles(styles)(Video);
