import { Theme, createStyles } from '@material-ui/core';

export const styles = (theme: Theme) =>
    createStyles({
        root: {
            position: 'relative',
            height: '100%',
            width: '100%',
        },
        video: {
            height: '100%',
            width: '100%',
            border: 'none',
            [theme.breakpoints.up(1441)]: { objectFit: 'cover' },
        },
        controls: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        },
        controlButton: {
            '& .controlIcon': {
                color: '#FEFEFE',
                fontSize: '80px',
            },
        },
    });
