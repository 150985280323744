import { Theme, createStyles } from '@material-ui/core/styles';

export const styles = (theme: Theme) =>
    createStyles({
        root: {
            fontFamily: 'MuseoSans, sans-serif',
            width: '1338px',
            marginBottom: '286px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            [theme.breakpoints.down(1350)]: {
                width: '100%',
            },
            [theme.breakpoints.down(1200)]: {
                flexDirection: 'column-reverse',
                alignItems: 'center',
            },
            [theme.breakpoints.down(550)]: { marginBottom: '163px' },
        },
        carousel: {
            position: 'relative',
            width: '705px',
            maxWidth: '705px',
            height: '695px',
            marginRight: '200px',
            overflow: 'hidden',
            '& #dots-payments': {
                width: '210px',
                position: 'absolute',
                zIndex: theme.zIndex.appBar,
                display: 'flex',
                flexDirection: 'row',
                justfiyContent: 'center',
                alignItems: 'center',
                left: '50%',
                top: '87%',
                transform: 'translate(-50%, -87%)',
            },
            [theme.breakpoints.down(1200)]: {
                width: '100%',
                marginRight: '0',
                '& #dots-payments': {
                    width: '20%',
                    transform: 'translate(-50%, -87%)',
                },
            },
            [theme.breakpoints.down(701)]: {
                height: '100vw',
                '& #dots-payments': { left: '45%' },
            },
            [theme.breakpoints.down(501)]: {
                '& #dots-payments': { left: '40%' },
            },
        },
        textContainer: {
            width: '32%',
            marginBottom: '205px',
            '& #titleContainer': {
                marginBottom: '130px',
                '& .titleLight': {
                    fontSize: '32.07px',
                    fontWeight: 500,
                    color: '#3E4375',
                    letterSpacing: '2px',
                    marginBottom: '0',
                },
                '& .title': {
                    fontSize: '59.57px',
                    fontWeight: 900,
                    color: '#3E4375',
                    letterSpacing: '7px',
                    marginBottom: '0',
                },
                '& #bot': {
                    marginBottom: '17px',
                    marginLeft: '30px',
                },
            },
            '& #text': {
                width: '307px',
                fontSize: '18.33px',
                color: '#3D4274',
                letterSpacing: '0.75px',
                lineHeight: '26.35px',
            },
            [theme.breakpoints.down(1200)]: {
                width: '40%',
                marginBottom: '80px',
                '& #titleContainer': {
                    marginBottom: '80px',
                },
                '& #text': {
                    width: '92%',
                },
            },
            [theme.breakpoints.down(1000)]: {
                width: '56%',
                '& #titleContainer': {
                    '& .titleLight': {
                        fontSize: '29.7px !important',
                        letterSpacing: '2.13px !important',
                    },
                    '& .title': {
                        fontSize: '55.2px !important',
                        letterSpacing: '7.4px !important',
                    },
                },
                '& #text': { width: '100%' },
            },
            [theme.breakpoints.down(769)]: {
                '& #text': {
                    fontSize: '21px',
                    letterSpacing: '0.4px',
                    lineHeight: '30px',
                },
            },
            [theme.breakpoints.down(701)]: {
                '& #titleContainer': {
                    '& .titleLight': {
                        fontSize: '28.13px !important',
                        letterSpacing: '2px !important',
                    },
                    '& .title': {
                        fontSize: '52.24px !important',
                        letterSpacing: '6px !important',
                    },
                },
                '& #text': {
                    width: '95%',
                    fontSize: '19px',
                    letterSpacing: '0.34px',
                    lineHeight: '28px',
                },
            },
            [theme.breakpoints.down(650)]: {
                marginBottom: '64px',
                '& #titleContainer': {
                    '& .titleLight': {
                        fontSize: '25px !important',
                        letterSpacing: '1.8px !important',
                    },
                    '& .title': {
                        fontSize: '45px !important',
                        letterSpacing: '5.5px !important',
                    },
                },
                '& #text': {
                    width: '100%',
                    fontSize: '16px',
                    letterSpacing: '0.5px',
                    lineHeight: '26px',
                },
            },
            [theme.breakpoints.down(501)]: {
                '& #titleContainer': {
                    '& .titleLight': {
                        fontSize: '16.07px !important',
                        letterSpacing: '1.14px !important',
                    },
                    '& .title': {
                        fontSize: '29.85px !important',
                        letterSpacing: '3.43px !important',
                    },
                    '& #bot': {
                        marginBottom: '5px !important',
                        marginLeft: '0 !important',
                    },
                },
                '& #text': {
                    width: '95%',
                    fontSize: '10.86px',
                    letterSpacing: '0.45px',
                    lineHeight: '16px',
                },
            },
        },
    });
