import { Theme, createStyles } from '@material-ui/core/styles';

export const styles = (theme: Theme) =>
    createStyles({
        root: {
            fontFamily: 'MuseoSans, sans-serif',
            width: '60.48%',
            marginBottom: '343px',
            marginLeft: '30%',
            display: 'flex',
            flexDirection: 'row',
            [theme.breakpoints.up(1441)]: {
                maxWidth: '870px',
                margin: '0 auto 343px auto',
            },
            [theme.breakpoints.down(1200)]: {
                width: '75%',
                marginBottom: '250px',
                marginLeft: '20%',
            },
            [theme.breakpoints.down(1000)]: {
                width: '79%',
                marginBottom: '245px',
                marginLeft: '2%',
            },
            [theme.breakpoints.down(940)]: {
                flexDirection: 'column-reverse',
            },
            [theme.breakpoints.down(701)]: {
                marginLeft: '21%',
            },
            [theme.breakpoints.down(501)]: {
                width: '57%',
                marginBottom: '163px',
                marginLeft: '22.28%',
            },
        },
        gif: {
            width: '35.48%',
            [theme.breakpoints.down(940)]: {
                width: '65%',
                marginLeft: '23.3%',
            },
            [theme.breakpoints.down(701)]: { marginLeft: '0' },
            [theme.breakpoints.down(501)]: { width: '100%' },
        },
        textContainer: {
            width: '41.1%',
            marginLeft: '23.3%',
            '& #titleContainer': {
                marginBottom: '133.65px',
            },
            '& .title': {
                fontSize: '59.57px',
                fontWeight: 900,
                color: '#3E4375',
                letterSpacing: '7px',
                marginBottom: '0',
            },
            '& #bot': {
                marginBottom: '10px',
                marginLeft: '20px',
            },
            '& #sub': {
                fontSize: '32.07px',
                fontWeight: 500,
                color: '#3E4375',
                letterSpacing: '2px',
            },
            '& #text': {
                fontSize: '18.33px',
                fontWeight: 500,
                color: '#3D4274',
                letterSpacing: '0.75px',
                lineHeight: '26.35px',
                width: '93%',
            },
            [theme.breakpoints.down(1000)]: {
                width: '75%',
                marginBottom: '88px',
                '& #titleContainer': {
                    marginBottom: '78px',
                },
                '& .title': {
                    fontSize: '55.24px',
                    letterSpacing: '6.34px',
                },
                '& #bot': { marginLeft: '55.2px' },
                '& #sub': {
                    fontSize: '29.7px',
                    letterSpacing: '2.11px',
                },
            },
            [theme.breakpoints.down(769)]: {
                '& #text': {
                    fontSize: '21px',
                    letterSpacing: '0.75px',
                    lineHeight: '30px',
                },
            },
            [theme.breakpoints.down(701)]: {
                marginLeft: '0',
                '& .title': {
                    fontSize: '52.24px',
                    letterSpacing: '6px',
                },
                '& #bot': { marginLeft: '52px' },
                '& #sub': {
                    fontSize: '28.13px',
                    letterSpacing: '2px',
                },
                '& #text': {
                    fontSize: '19px',
                    letterSpacing: '0.34px',
                    lineHeight: '28px',
                },
            },
            [theme.breakpoints.down(650)]: {
                width: '85%',
                '& .title': {
                    fontSize: '45px',
                    letterSpacing: '5.5px',
                },
                '& #bot': { marginLeft: '0' },
                '& #sub': {
                    fontSize: '25px',
                    letterSpacing: '1.8px',
                },
                '& #text': {
                    fontSize: '16px',
                    letterSpacing: '0.5px',
                    lineHeight: '26px',
                },
            },
            [theme.breakpoints.down(501)]: {
                marginBottom: '57px',
                '& #titleContainer': {
                    marginBottom: '45px',
                },
                '& .title': {
                    fontSize: '29.85px',
                    letterSpacing: '3.43px',
                },
                '& #sub': {
                    fontSize: '16.07px',
                    letterSpacing: '1.14px',
                },
                '& #text': {
                    fontSize: '10.86px',
                    letterSpacing: '0.45px',
                    lineHeight: '16px',
                    width: '100%',
                },
            },
        },
    });
