// React imports
import React from 'react';

// UI - style imports
import { WithStyles, withStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';

// local imports
import { styles } from './MiniTerms.style';

function MiniTerms(props: WithStyles<typeof styles>) {
    const { classes } = props;

    return (
        <Box className={classes.root}>
            <hr />

            <p>
                Cuenca es una entidad financiera regulada y supervisada por la CNBV, Banco de México y CONDUSEF. Fuimos
                autorizados para organizarnos y operar como una Institución de Fondos de Pago Electrónico de conformidad
                con la Ley para Regular las Instituciones de Tecnología Financiera.
            </p>
        </Box>
    );
}

export default withStyles(styles)(MiniTerms);
