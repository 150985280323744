import { Theme, createStyles } from '@material-ui/core/styles';

export const styles = (theme: Theme) =>
    createStyles({
        root: {
            width: '219px',
            maxWidth: '219px',
            color: '#3D4274',
            fontFamily: 'MuseoSans, sans-serif',
            fontSize: '18.33px',
            letterSpacing: '2px',
            fontWeight: 900,
            display: 'flex',
            flexDirection: 'column',
            '& #idImg': {
                marginBottom: '50px',
                '& img': { marginBottom: 0 },
            },
            '& #left': {
                margin: '0',
                textAlign: 'left',
            },
            '& #right': {
                margin: '0',
                textAlign: 'right',
            },
            [theme.breakpoints.down(1000)]: {
                width: '100%',
                maxWidth: '100%',
                flexDirection: 'row',
                '& #idImg': {
                    minWidth: '219px',
                    marginBottom: '40px',
                    marginRight: '30%',
                },
                '& #left': {
                    width: '40%',
                    textAlign: 'left',
                },
                '& #right': {
                    width: '40%',
                    textAlign: 'left',
                },
            },
            [theme.breakpoints.down(701)]: {
                fontSize: '15px',
                letterSpacing: '1.5px',
                '& #idImg': {
                    minWidth: '179px',
                    marginRight: '20%',
                },
            },
            [theme.breakpoints.down(501)]: {
                fontSize: '8.91px',
                letterSpacing: '1.14px',
                '& #idImg': {
                    minWidth: '137px',
                    maxWidth: '137px',
                    marginBottom: '21px',
                    marginRight: '33px',
                },
            },
        },
    });
