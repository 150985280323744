import { Theme, createStyles } from '@material-ui/core/styles';

// gif
import gridGif from 'images/gifs/grid-home.gif';

export const styles = (theme: Theme) =>
    createStyles({
        root: {
            fontFamily: 'MuseoSans, sans-serif',
            color: theme.palette.primary.main,
            marginTop: '237px',
            marginLeft: '25%',
            marginBottom: 0,
            width: '58%',
            '& #first': {
                marginTop: '57.45px',
                fontWeight: 500,
                fontSize: '41.24px',
                letterSpacing: '1.5px',
                textAlign: 'left',
                color: '#3D4274',
                lineHeight: '51.55px',
            },
            '& #second': {
                width: '99%',
                marginTop: '85px',
                fontSize: '18.33px',
                letterSpacing: '0.75px',
                lineSpacing: '5px',
                color: '#3D4274',
                lineHeight: '26.35px',
            },
            [theme.breakpoints.up(1441)]: {
                maxWidth: '835px',
                margin: '237px auto 0 auto',
            },
            [theme.breakpoints.down(1200)]: {
                width: '70.83%',
            },
            [theme.breakpoints.down(769)]: {
                marginLeft: '22.13%',
                '& #first': {
                    fontSize: '36px',
                    letterSpacing: '1.26px',
                    lineHeight: '51px',
                },
                '& #second': {
                    fontSize: '21px',
                    letterSpacing: '0.75px',
                    lineHeight: '30px',
                },
            },
            [theme.breakpoints.down(701)]: {
                '& #first': {
                    fontSize: '30px',
                    letterSpacing: '1.31px',
                    lineHeight: '45px',
                },
                '& #second': {
                    fontSize: '19px',
                    letterSpacing: '0.34px',
                    lineHeight: '28px',
                },
            },
            [theme.breakpoints.down(650)]: {
                marginTop: '150px',
                '& #first': {
                    marginTop: '50px',
                    fontSize: '25px',
                    letterSpacing: '1px',
                    lineHeight: '40px',
                },
                '& #second': {
                    marginTop: '65px',
                    fontSize: '16px',
                    letterSpacing: '0.5px',
                    lineHeight: '26px',
                },
            },
            [theme.breakpoints.down(501)]: {
                marginLeft: '21.85%',
                marginTop: '125px',
                '& #first': {
                    marginTop: '45px',
                    fontSize: '17.14px',
                    letterSpacing: '0.75px',
                    lineHeight: '25.71px',
                },
                '& #second': {
                    marginTop: '53.71px',
                    fontSize: '10.86px',
                    letterSpacing: '0.45px',
                    lineHeight: '16px',
                },
            },
        },
        title: {
            fontWeight: 900,
            fontSize: '108.82px',
            letterSpacing: '7px',
            marginBottom: '0',
            [theme.breakpoints.down(769)]: {
                letterSpacing: '9.5px',
            },
            [theme.breakpoints.down(701)]: {
                fontSize: '105px',
                letterSpacing: '6.92px',
            },
            [theme.breakpoints.down(650)]: {
                fontSize: '80px',
                letterSpacing: '4px',
            },
            [theme.breakpoints.down(501)]: {
                fontSize: '60px',
                letterSpacing: '3.95px',
            },
        },
        titleSecondPart: {
            marginLeft: '100px',
            [theme.breakpoints.down(650)]: { marginLeft: '80px' },
            [theme.breakpoints.down(501)]: { marginLeft: '60px' },
        },
    });
