import { Theme, createStyles } from '@material-ui/core/styles';

export const styles = (theme: Theme) =>
    createStyles({
        root: {
            position: 'absolute',
            height: '694px',
            width: '704px',
            zIndex: theme.zIndex.speedDial,
            [theme.breakpoints.down(769)]: {
                width: '100%',
                maxWidth: '704px',
            },
            [theme.breakpoints.down(701)]: {
                height: '100vw',
            },
        },
        image: {
            marginBottom: '35px',
            '& img': { marginBottom: 0 },
            [theme.breakpoints.down(701)]: {
                marginBottom: '10px',
            },
        },
        description: {
            fontFamily: 'MuseoSans, sans-serif',
            fontWeight: 700,
            fontSize: '14.5px',
            color: '#A2A5BC',
            letterSpacing: '0.88px',
            lineHeight: '21px',
            textAlign: 'center',
            whiteSpace: 'nowrap',
            [theme.breakpoints.down(1300)]: { marginLeft: '40%' },
            [theme.breakpoints.down(800)]: {
                float: 'right',
                marginLeft: 0,
                marginRight: '30px',
            },
            [theme.breakpoints.down(701)]: {
                fontSize: '15px',
                letterSpacing: '1.5px',
                marginRight: '62px',
            },
            [theme.breakpoints.down(501)]: {
                fontSize: '8.91px',
                letterSpacing: '1.14px',
            },
        },
    });
