// React imports
import React from 'react';

// UI – style imports
import { WithStyles, withStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';

// local imports
import { styles } from './ImageCarousel.style';
import agua from './images/agua.jpg';
import cable from './images/cable.jpg';
import electricidad from './images/electricidad.jpg';
import gas from './images/gas.jpg';
import internet from './images/internet.jpg';
import movil from './images/movil.jpg';
import tarjeta from './images/tarjeta.jpg';
import television from './images/television.jpg';
import telefono from './images/telefono.jpg';

type ImageCarouselProps = {
    type: string;
    style: object;
} & typeof defaultProps;

const defaultProps = {
    type: 'agua',
};

const DATA_SOURCE = {
    agua: [agua, 'AGUA'],
    cable: [cable, 'CABLE'],
    electricidad: [electricidad, 'ELECTRICIDAD'],
    gas: [gas, 'GAS'],
    internet: [internet, 'INTERNET'],
    movil: [movil, 'PLAN MÓVIL / TIEMPO AIRE'],
    tarjeta: [tarjeta, 'TARJETA DE CRÉDITO'],
    television: [television, 'TELEVISIÓN SATELITAL'],
    telefono: [telefono, 'TELEFONO FIJO'],
};

function ImageCarousel(props: ImageCarouselProps & WithStyles<typeof styles>) {
    const { classes, type, ...rest } = props;
    const data = DATA_SOURCE[type];

    return (
        <Box className={classes.root} {...rest}>
            <Box className={classes.image}>
                <img src={data[0]} alt="Random Image Placeholder" />
            </Box>

            <p className={classes.description}>{data[1]}</p>
        </Box>
    );
}
ImageCarousel.defaultProps = defaultProps;

export default withStyles(styles)(ImageCarousel);
