// React imports
import React from 'react';

// UI – style imports
import { WithStyles, withStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';

// local imports
import { styles } from './Community.style';
import Video from 'components/Video';
import tejiendoImage from 'images/tejiendo-comunidad.jpg';
import videoFundas from 'images/videos/bibiana_fundas.mp4';
import videoPoster from 'images/frame-tenango.png';

function Community(props: WithStyles<typeof styles>) {
    const { classes } = props;

    return (
        <Box className={classes.root}>
            <Box className={classes.content}>
                <Box className={classes.textContainer}>
                    <Box className={classes.titleContainer}>
                        <p id="top">Tejiendo</p>
                        <p className="title">CO</p>
                        <p id="snd" className="title">
                            MUNI
                        </p>
                        <p id="trd" className="title">
                            DAD
                        </p>
                    </Box>

                    <p id="text">
                        Somos una comunidad diversa e incluyente, donde artesanos y artistas aportan sueños que se
                        vuelven tejidos, lienzos y diseños; hilos que hermanan a las comunidades de Tlalixtac de
                        Cabrera, San Baltazar, San Antonio, los Chilatecas, Yaxe, Santos Reyes, Xoxo, Ejutla en Oaxaca;
                        pero también de la Sierra Norte Cuetzalan y Sierra Negra Chilac de Puebla, hasta llegar a la
                        zona Tzotzil de los Altos de Chiapas. ¡Y todos somos Cuenca!
                    </p>
                </Box>

                <Box className={classes.image}>
                    <img src={tejiendoImage} alt="Tejido artesanal" />
                </Box>
            </Box>

            <Box className={classes.video}>
                <Video url={videoFundas} imagePreview={videoPoster} title="Historias Cuenca: La Funda de una Tarjeta" />
            </Box>
        </Box>
    );
}

export default withStyles(styles)(Community);
