// React imports
import React, { useState, useEffect } from 'react';

// UI – style imports
import { WithStyles, withStyles } from '@material-ui/core';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';

// local imports
import { styles } from './Description.style';
import dashTarjeta from 'images/dash-tarjeta.jpg';

function Description(props: WithStyles<typeof styles>) {
    const { classes } = props;
    const [showSafariMargin, setMargin] = useState(false);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const { userAgent } = window.navigator;
            setMargin(/^((?!chrome|android).)*safari/i.test(userAgent));
        }
    }, []);

    return (
        <Box className={clsx(classes.root, showSafariMargin && 'safari-margin')}>
            <Box id="phoneCard">
                <img src={dashTarjeta} alt="Tarjeta y app Cuenca" />
            </Box>

            <Box className={classes.textContainer}>
                <Box className={classes.titleContainer}>
                    <p id="topTitle">La cuenta que</p>
                    <div id="title">
                        <p id="top">NOSOTROS</p>
                        <p id="bot">USAMOS</p>
                    </div>
                </Box>

                <p id="text">
                    Con Cuenca, cuenta de fondos de pago electrónico, es muy fácil abrir una cuenta en minutos, enviar y
                    recibir transferencias bancarias vía SPEI, sólo necesitas usar tu CLABE interbancaria, puedes
                    transferir entre cuentas Cuenca, vía Whatsapp y bancos; tienes acceso a tus fondos los 365 días del
                    año, las 24 horas del día. Y recuerda, funciona en todo México.
                </p>
                <p id="text">
                    Aquí los servicios financieros son para todxs. Trabajamos en el futuro, con tecnología innovadora,
                    para mejorar la cultura financiera en México. Creamos la cuenta con la que soñamos, la que nosotrxs
                    usamos.
                </p>
            </Box>
        </Box>
    );
}

export default withStyles(styles)(Description);
