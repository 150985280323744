import { Theme, createStyles } from '@material-ui/core/styles';

export const styles = (theme: Theme) =>
    createStyles({
        root: {
            fontFamily: 'MuseoSans, sans-serif',
            width: '100%',
            marginBottom: '304px',
            [theme.breakpoints.down(550)]: { marginBottom: '163px' },
        },
        content: {
            width: '75%', // 1037px
            marginBottom: '92.78px',
            display: 'flex',
            flexDirection: 'row',
            float: 'right',
            [theme.breakpoints.up(1441)]: { maxWidth: '75%' },
            [theme.breakpoints.down(1200)]: { width: '85%' },
            [theme.breakpoints.down(1000)]: {
                flexDirection: 'column',
                width: '71.22%',
            },
            [theme.breakpoints.down(701)]: { width: '78.14%' },
            [theme.breakpoints.down(550)]: { width: '78%' },
        },
        textContainer: {
            width: '31.44%', // 326px
            marginRight: '16.87%',
            '& #text': {
                fontSize: '18.33px',
                fontWeight: 500,
                color: '#3D4274',
                letterSpacing: '0.75px',
                lineHeight: '26.45px',
            },
            [theme.breakpoints.down(1200)]: {
                width: '40%',
                marginRight: '8%',
            },
            [theme.breakpoints.down(1000)]: {
                width: '72.21%',
                marginBottom: '93px',
                marginRight: '0',
                '& #text': {
                    fontSize: '21px',
                    letterSpacing: '0.75px',
                    lineHeight: '30px',
                },
            },
            [theme.breakpoints.down(701)]: {
                '& #text': {
                    fontSize: '19px',
                    letterSpacing: '0.34px',
                    lineHeight: '28px',
                },
            },
            [theme.breakpoints.down(650)]: {
                width: '90%',
                '& #text': {
                    fontSize: '16px',
                    letterSpacing: '0.5px',
                    lineHeight: '26px',
                },
            },
            [theme.breakpoints.down(501)]: {
                width: '90%',
                marginBottom: '53px',
                '& #text': {
                    fontSize: '10.86px',
                    letterSpacing: '0.45px',
                    lineHeight: '16px',
                    width: '75%',
                },
            },
        },
        titleContainer: {
            width: '88.34%',
            marginBottom: '130px',
            '& #top': {
                fontSize: '32.07px',
                fontWeight: 500,
                color: '#3D4274',
                letterSpacing: '2px',
                marginBottom: '0',
            },
            '& .title': {
                fontSize: '59.57px',
                fontWeight: 900,
                color: '#3D4274',
                letterSpacing: '7px',
                marginBottom: '0',
            },
            '& #snd': { marginLeft: '30px' },
            '& #trd': { marginLeft: '145px' },
            [theme.breakpoints.down(1000)]: {
                marginBottom: '85px',
                '& #top': {
                    fontSize: '29.7px',
                    letterSpacing: '2.11px',
                },
                '& .title': {
                    fontSize: '55.4px',
                    letterSpacing: '6.34px',
                },
            },
            [theme.breakpoints.down(701)]: {
                marginBottom: '90px',
                '& #top': {
                    fontSize: '28.13px',
                    letterSpacing: '2px',
                },
                '& .title': {
                    fontSize: '52.24px',
                    letterSpacing: '6px',
                },
                '& #snd': { marginLeft: '20px' },
                '& #trd': { marginLeft: '130px' },
            },
            [theme.breakpoints.down(650)]: {
                marginBottom: '60px',
                '& #top': {
                    fontSize: '25px',
                    letterSpacing: '1.8px',
                },
                '& .title': {
                    fontSize: '45px',
                    letterSpacing: '5.5px',
                },
                '& #trd': { marginLeft: '90px' },
            },
            [theme.breakpoints.down(501)]: {
                '& #top': {
                    fontSize: '16.07px',
                    letterSpacing: '1.14px',
                },
                '& .title': {
                    fontSize: '29.85px',
                    letterSpacing: '3.43px',
                },
                '& #snd': { marginLeft: '10px' },
                '& #trd': { marginLeft: '75px' },
            },
        },
        image: {
            width: '51.69%',
            '& img': {
                width: '100%',
                objectFit: 'cover',
            },
            [theme.breakpoints.up(1441)]: {
                height: '100%',
                width: '100%',
            },
            [theme.breakpoints.down(1000)]: { width: '100%' },
        },
        video: {
            height: '805px',
            width: '100%',
            clear: 'both',
            '& video': {
                height: '100%',
                width: '100%',
            },
            [theme.breakpoints.up(1479)]: { height: '900px' },
            [theme.breakpoints.up(1620)]: { height: '1100px' },
            [theme.breakpoints.up(2000)]: { height: '1500px' },
            [theme.breakpoints.down(850)]: { height: '432px' },
            [theme.breakpoints.down(701)]: { height: '394px' },
            [theme.breakpoints.down(501)]: { height: '330px' },
        },
    });
