// React imports
import React from 'react';

// UI – style imports
import { WithStyles, withStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';

// local imports
import { styles } from './Investors.style';
import stripeLogo from 'images/logos/stripe-logo.svg';
import andreessenLogo from 'images/logos/a16n-logo.svg';
import kaszekLogo from 'images/logos/kaszek-logo.svg';

function Investors(props: WithStyles<typeof styles>) {
    const { classes } = props;

    return (
        <Box className={classes.root}>
            <Box className={classes.textContainer}>
                <Box className={classes.titleContainer}>
                    <p id="top">Nuestros</p>
                    <p className="title">INVER</p>
                    <p id="snd" className="title">
                        SIONISTAS
                    </p>
                </Box>

                <p id="text">
                    Somos una comunidad que cree en la transformación de los servicios financieros en México. Nuestros
                    inversionistas participan con capital que contribuye a la creación de una nueva cultura financiera
                    que da acceso a todos los servicios que antes unos cuantos tenían.
                </p>
            </Box>

            <Box className={classes.logoContainer}>
                <a href="https://stripe.com/mx/about" target="_blank">
                    <img src={stripeLogo} alt="Stripe logo" />
                </a>

                <a href="https://a16z.com/" target="_blank">
                    <img src={andreessenLogo} alt="Andreessen Horowitz logo" />
                </a>

                <a href="https://www.kaszek.com/" target="_blank">
                    <img src={kaszekLogo} alt="Kaszek Ventures logo" />
                </a>
            </Box>
        </Box>
    );
}

export default withStyles(styles)(Investors);
