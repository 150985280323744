// React imports
import React from 'react';

// UI - style imports
import { WithStyles, withStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import hacienda from 'images/hacienda_datos.png';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

// local imports
import { styles } from './Hacienda.style';

function Hacienda(props: WithStyles<typeof styles>) {
    const { classes } = props;

    return (
        <Box className={classes.root}>
            <TransformWrapper initialScale={1} initialPositionX={0} maxScale={2.5} initialPositionY={0}>
                <TransformComponent>
                    <img src={hacienda} alt="Hacienda datos" />
                </TransformComponent>
            </TransformWrapper>
        </Box>
    );
}

export default withStyles(styles)(Hacienda);
