// React imports
import React from 'react';

// UI – style imports
import { WithStyles, withStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';

// local imports
import { styles } from './Deposits.style';
import efectivo from 'images/efectivo.jpg';

function Deposits(props: WithStyles<typeof styles>) {
    const { classes } = props;

    return (
        <Box className={classes.root}>
            <Box className={classes.textContainer}>
                <Box className={classes.titleContainer}>
                    <p id="top">365 días,</p>
                    <p className="title">EN</p>
                    <p id="snd" className="title">
                        TIEMPO
                    </p>
                    <p id="trd" className="title">
                        REAL
                    </p>
                </Box>
                <p id="text">
                    No importa dónde estés, no importa si es día, noche o fin de semana. Cuenca es una cuenta segura,
                    puedes retirar en la red de cajeros electrónicos nacionales e internacionales afiliados a{' '}
                    <a
                        className={classes.linkInsideP}
                        href="https://www.mastercard.es/es-es/consumidores/conoce-las-caracteristicas-y-ventajas/localizador-cajero-automatico.html"
                        target="_blank"
                    >
                        Mastercard
                    </a>
                    ; todo en tiempo real y 24/7. ¡<em>Tap</em> y listo!
                </p>
            </Box>

            <Box className={classes.image}>
                <img src={efectivo} alt="Deposita efectivo a Cuenca" />
            </Box>
        </Box>
    );
}

export default withStyles(styles)(Deposits);
