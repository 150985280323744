import { Theme, createStyles } from '@material-ui/core/styles';

export const styles = (theme: Theme) =>
    createStyles({
        root: {
            fontFamily: 'MuseoSans, sans-serif',
            width: '100%',
            marginBottom: '418px',
            display: 'flex',
            flexDirection: 'row-reverse',
            float: 'right',
            [theme.breakpoints.up(1441)]: { width: '73%' },
            [theme.breakpoints.down(1000)]: {
                marginBottom: '340px',
                flexDirection: 'column',
            },
            [theme.breakpoints.down(701)]: { marginBottom: '240px' },
            [theme.breakpoints.down(501)]: { marginBottom: '163px' },
        },
        image: {
            height: '64.96%',
            width: '36.6%',
            marginLeft: '3.76%',
            marginTop: '246px',
            '& img': {
                height: '100%',
                width: '100%',
                objectFit: 'cover',
            },
            [theme.breakpoints.up(1441)]: {
                height: '100%',
                width: '100%',
            },
            [theme.breakpoints.down(1000)]: {
                width: '100%',
                marginBottom: '108px',
                marginLeft: 0,
                marginTop: 0,
            },
            [theme.breakpoints.down(550)]: { marginBottom: '78px' },
        },
        textContainer: {
            width: '32.71%',
            '& #text': {
                fontSize: '18.33px',
                fontWeight: 500,
                color: '#3D4274',
                letterSpacing: '0.75px',
                lineHeight: '26.35px',
                width: '68%',
            },
            [theme.breakpoints.down(1350)]: {
                width: '45%',
            },
            [theme.breakpoints.down(1000)]: {
                width: '60.68%',
                marginLeft: '22%',
                '& #text': { width: '100%' },
            },
            [theme.breakpoints.down(769)]: {
                '& #text': {
                    fontSize: '21px',
                    letterSpacing: '0.75px',
                    lineHeight: '30px',
                },
            },
            [theme.breakpoints.down(701)]: {
                '& #text': {
                    width: '95%',
                    fontSize: '19px',
                    letterSpacing: '0.34px',
                    lineHeight: '28px',
                },
            },
            [theme.breakpoints.down(650)]: {
                width: '70%',
                marginLeft: '22.28%',
                '& #text': {
                    width: '100%',
                    fontSize: '16px',
                    letterSpacing: '0.5px',
                    lineHeight: '26px',
                },
            },
            [theme.breakpoints.down(501)]: {
                width: '58%',
                marginLeft: '22.28%',
                '& #text': {
                    fontSize: '10.86px',
                    letterSpacing: '0.13px',
                    lineHeight: '16px',
                },
            },
        },
        titleContainer: {
            marginBottom: '135.65px',
            '& #top': {
                fontSize: '32.07px',
                fontWeight: 500,
                color: '#3D4274',
                letterSpacing: '2px',
                marginBottom: '0',
            },
            '& #title': {
                fontSize: '59.57px',
                fontWeight: 900,
                color: '#3D4274',
                letterSpacing: '7px',
                marginBottom: '0',
            },
            [theme.breakpoints.down(1000)]: {
                marginBottom: '73px',
                '& #top': {
                    fontSize: '29.7px',
                    letterSpacing: '2.11px',
                },
                '& #title': {
                    fontSize: '55.24px',
                    letterSpacing: '6.34px',
                },
            },
            [theme.breakpoints.down(701)]: {
                marginBottom: '70px',
                '& #top': {
                    fontSize: '28.13px',
                    letterSpacing: '2px',
                },
                '& #title': {
                    fontSize: '52.24px',
                    letterSpacing: '6px',
                },
            },
            [theme.breakpoints.down(650)]: {
                '& #top': {
                    fontSize: '25px',
                    letterSpacing: '1.8px',
                },
                '& #title': {
                    fontSize: '45px',
                    letterSpacing: '5.5px',
                },
            },
            [theme.breakpoints.down(501)]: {
                marginBottom: '50px',
                '& #top': {
                    fontSize: '16.07px',
                    letterSpacing: '1.14px',
                },
                '& #title': {
                    fontSize: '29.85px',
                    letterSpacing: '3.43px',
                },
            },
        },
    });
