// React imports
import React from 'react';

// UI – style imports
import { WithStyles, withStyles, useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';

// local imports
import { styles } from './Support.style';
import gif from 'images/gifs/wapp-conversacion-2133px.gif';
import smallGif from 'images/gifs/wapp-conversacion-768px.gif';

function Support(props: WithStyles<typeof styles>) {
    const { classes } = props;
    const gifSize = useMediaQuery('(min-width:1100px)');

    return (
        <Box className={classes.root}>
            <Box className={classes.gif}>
                <img src={gifSize ? gif : smallGif} alt="Conversación de soporte Cuenca en Whatsapp" />
            </Box>

            <Box className={classes.content}>
                <Box className={classes.textContainer}>
                    <Box className={classes.titleContainer}>
                        <p id="top">Servicio</p>
                        <p className="title">CON</p>
                        <p className="title">EMPATÍA</p>
                    </Box>

                    <p className={classes.text}>
                        Cuenca es un servicio financiero del siglo XXI, fácil de usar, inmediato y sobre todo humano,
                        pensado en ti y en tus necesidades financieras. ¿Quieres hablar con nosotros? Platica con
                        personas reales por WhatsApp: lunes a viernes 10:00 - 22:00 hrs y sábado y domingo 15:00 - 22:00
                        hrs. ¡Cuenca es la única cuenta que necesitas!
                    </p>
                </Box>
            </Box>
        </Box>
    );
}

export default withStyles(styles)(Support);
