// React imports
import React from 'react';

// UI – style imports
import { WithStyles, withStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';

// local imports
import { styles } from './OpenSource.style';
import semillaImage from 'images/semilla-innovacion.png';

function OpenSource(props: WithStyles<typeof styles>) {
    const { classes } = props;

    return (
        <Box className={classes.root}>
            <Box className={classes.image}>
                <img src={semillaImage} alt="Drone de Cuenca" />
            </Box>

            <Box className={classes.textContainer}>
                <Box className={classes.titleContainer}>
                    <p id="top">La semilla de la</p>
                    <p id="title">INNOVACIÓN</p>
                </Box>

                <p id="text">
                    Creamos y compartimos un <em>software</em> libre para el ecosistema tecnológico; utilizamos código
                    abierto libre de pagos de derechos y/o comisiones. <em>Open Source</em> crea comunidad estableciendo
                    una nueva relación con la tecnología, donde todos los innovadores y programadores que lo deseen
                    puedan utilizar, estudiar, modificar y distribuir nuestro <em>software</em> libremente.
                </p>

                <p id="text">
                    Cuenca Tecnología Financiera, S.A. de C.V. Institución de Fondos de Pago Electrónico, es una entidad
                    financiera legalmente constituída y regulada por la Ley FinTech; además, como IFPE, está regulada
                    por la Comisión Nacional Bancaria y de Valores (CNBV). Trabaja con los más innovadores sistemas de
                    prevención de lavado de dinero y verificación de usuario para que tu cuenta esté segura siempre.
                </p>
            </Box>
        </Box>
    );
}

export default withStyles(styles)(OpenSource);
