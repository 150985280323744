import { Theme, createStyles } from '@material-ui/core/styles';

export const styles = (theme: Theme) =>
    createStyles({
        root: {
            fontFamily: 'MuseoSans, sans-serif',
            color: theme.palette.primary.main,
            width: '1329px',
            marginBottom: '325px',
            display: 'flex',
            flexDirection: 'row',
            '& #phoneCard': {
                height: '100%',
                width: '704px',
                '& img': {
                    height: '100%',
                    width: '100%',
                    objectFit: 'cover',
                },
            },
            [theme.breakpoints.down(1400)]: { width: '100%' },
            [theme.breakpoints.down(1000)]: {
                marginBottom: '200px',
                flexDirection: 'column',
                '& #phoneCard': {
                    height: '575px',
                    width: '100%',
                },
            },
            [theme.breakpoints.down(701)]: {
                marginBottom: '120px',
                '& #phoneCard': { height: '500px' },
            },
            [theme.breakpoints.down(650)]: {
                marginBottom: '125px',
                '& #phoneCard': { height: '308px' },
            },
        },
        textContainer: {
            width: '425px',
            marginBottom: '123px',
            marginLeft: '13.89%',
            marginTop: '15px',
            '& #text': {
                fontSize: '18.33px',
                letterSpacing: '0.75px',
                color: '#3D4274',
                lineHeight: '26.35px',
                width: '323px',
            },
            [theme.breakpoints.down(1200)]: {
                marginLeft: '10%',
            },
            [theme.breakpoints.down(1000)]: {
                width: '543px',
                marginBottom: '0',
                marginLeft: '22%',
                marginTop: '9%',
                '& #text': {
                    marginLeft: '21%',
                    width: '79%',
                },
            },
            [theme.breakpoints.down(769)]: {
                width: '75%',
                '& #text': {
                    fontSize: '21px',
                    letterSpacing: '0.75px',
                    lineHeight: '30px',
                },
            },
            [theme.breakpoints.down(701)]: {
                '& #text': {
                    marginLeft: '38%',
                    width: '55%',
                    fontSize: '19px',
                    letterSpacing: '0.34px',
                    lineHeight: '28px',
                },
            },
            [theme.breakpoints.down(650)]: {
                marginLeft: '15%',
                '& #text': {
                    marginLeft: '35%',
                    fontSize: '16px',
                    letterSpacing: '0.5px',
                    lineHeight: '26px',
                },
            },
            [theme.breakpoints.down(501)]: {
                width: '70.75%',
                marginLeft: '22.28%',
                '& #text': {
                    marginLeft: '29%',
                    width: '59.16%',
                    fontSize: '10.86px',
                    letterSpacing: '0.45px',
                    lineHeight: '16px',
                },
            },
        },
        titleContainer: {
            marginBottom: '120.65px',
            '& #topTitle': {
                fontSize: '32.07px',
                fontWeight: 500,
                letterSpacing: '2px',
                marginBottom: '0',
            },
            '& #title': {
                fontSize: '59.57px',
                fontWeight: 900,
                letterSpacing: '7px',
                '& #top': {
                    marginBottom: '0',
                },
                '& #bot': {
                    marginLeft: '120px',
                },
            },
            [theme.breakpoints.down(1000)]: {
                marginBottom: '14%',
                '& #topTitle': {
                    fontSize: '29.7px',
                    letterSpacing: '2.11px',
                },
                '& #title': {
                    fontSize: '55.24px',
                    letterSpacing: '6.34px',
                    '& #bot': {
                        marginLeft: '200px !important',
                    },
                },
            },
            [theme.breakpoints.down(701)]: {
                marginBottom: '15.35%',
                '& #topTitle': {
                    fontSize: '28.13px',
                    letterSpacing: '2px',
                },
                '& #title': {
                    fontSize: '52.24px',
                    letterSpacing: '6px',
                },
            },
            [theme.breakpoints.down(650)]: {
                '& #topTitle': {
                    fontSize: '25px',
                    letterSpacing: '1.8px',
                },
                '& #title': {
                    fontSize: '45px',
                    letterSpacing: '5.5px',
                },
            },
            [theme.breakpoints.down(501)]: {
                '& #topTitle': {
                    fontSize: '16.07px',
                    letterSpacing: '1.14px',
                },
                '& #title': {
                    fontSize: '29.85px',
                    letterSpacing: '3.43px',
                    '& #bot': {
                        marginLeft: '80px !important',
                    },
                },
            },
        },
    });
