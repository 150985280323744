import { Theme, createStyles } from '@material-ui/core/styles';

export const styles = (theme: Theme) =>
    createStyles({
        root: {
            fontFamily: 'MuseoSans, sans-serif',
            width: '56.25%',
            marginBottom: '580px',
            marginLeft: '30%',
            [theme.breakpoints.up(1441)]: {
                maxWidth: '810px',
                margin: '0 auto 580px auto',
            },
            [theme.breakpoints.down(1200)]: {
                width: '75%',
                marginBottom: '317px',
                marginTop: '-10px',
                marginLeft: '22%',
            },
            [theme.breakpoints.down(900)]: {
                width: '78%',
                marginLeft: '22%',
            },
            [theme.breakpoints.down(510)]: {
                marginLeft: '21%',
                marginBottom: '163px',
            },
        },
        topContainer: {
            marginBottom: '112.84px',
            display: 'flex',
            flexDirection: 'row',
            [theme.breakpoints.down(1200)]: {
                width: '86%',
                flexDirection: 'column',
            },
            [theme.breakpoints.down(650)]: {
                marginBottom: '50px',
            },
            [theme.breakpoints.down(550)]: {
                marginBottom: '30px',
            },
        },
        titleContainer: {
            marginRight: '9.3%',
            '& .title': {
                fontSize: '59.57px',
                fontWeight: 900,
                color: '#3D4274',
                letterSpacing: '7px',
                marginBottom: '0',
            },
            '& #snd': { marginLeft: '25px' },
            '& #td': { marginLeft: '25px' },
            '& #top': {
                fontSize: '32.07px',
                fontWeight: 500,
                color: '#3D4274',
                letterSpacing: '2px',
                marginBottom: '0',
            },
            [theme.breakpoints.down(1000)]: {
                marginBottom: '65px',
                marginRight: '0',
                '& .title': {
                    fontSize: '55.24px',
                    letterSpacing: '6.34px',
                },
                '& #snd': { marginLeft: '35px' },
                '& #td': { marginLeft: '35px' },
                '& #top': {
                    fontSize: '29.7px',
                    letterSpacing: '2.11px',
                },
            },
            [theme.breakpoints.down(701)]: {
                marginBottom: '100px',
                '& .title': {
                    fontSize: '52.24px',
                    letterSpacing: '6px',
                },
                '& #top': {
                    fontSize: '28.13px',
                    letterSpacing: '2px',
                },
            },
            [theme.breakpoints.down(650)]: {
                marginBottom: '70px',
                '& .title': {
                    fontSize: '45px',
                    letterSpacing: '5.5px',
                },
                '& #top': {
                    fontSize: '25px',
                    letterSpacing: '1.8px',
                },
            },
            [theme.breakpoints.down(501)]: {
                '& .title': {
                    fontSize: '29.85px',
                    letterSpacing: '3.43px',
                },
                '& #top': {
                    fontSize: '16.03px',
                    letterSpacing: '1.14px',
                },
            },
            [theme.breakpoints.down(400)]: {
                '& #snd': { marginLeft: '20px' },
                '& #td': { marginLeft: '20px' },
            },
        },
        topText: {
            width: '38.4%',
            fontSize: '18.33px',
            fontWeight: 500,
            color: '#3D4274',
            letterSpacing: '0.75px',
            lineHeight: '26.35px',
            [theme.breakpoints.down(1200)]: {
                width: '100%',
            },
            [theme.breakpoints.down(769)]: {
                fontSize: '21px',
                letterSpacing: '0.75px',
                lineHeight: '30px',
            },
            [theme.breakpoints.down(701)]: {
                width: '84%',
                fontSize: '19px',
                letterSpacing: '0.34px',
                lineHeight: '28px',
            },
            [theme.breakpoints.down(650)]: {
                width: '90%',
                fontSize: '16px',
                letterSpacing: '0.5px',
                lineHeight: '26px',
            },
            [theme.breakpoints.down(501)]: {
                fontSize: '10.86px',
                letterSpacing: '0.45px',
                lineHeight: '16px',
            },
        },
        midContainer: {
            '& #subtitle': {
                fontSize: '32.07px',
                fontWeight: 500,
                color: '#3D4274',
                letterSpacing: '2px',
            },
            '& .header': {
                fontSize: '18.33px',
                fontWeight: 900,
                color: '#3D4975',
                letterSpacing: '2px',
                marginBottom: '0',
                marginTop: '31px',
            },
            '& #bottom': {
                marginBottom: '60px',
            },
            [theme.breakpoints.down(1000)]: {
                '& #subtitle': {
                    fontSize: '29.7px',
                    letterSpacing: '2.11px',
                },
            },
            [theme.breakpoints.down(950)]: {
                '& #bottom': { marginTop: '-5px' },
            },
            [theme.breakpoints.down(769)]: {
                '& .header': {
                    fontSize: '21px',
                    letterSpacing: '0.75px',
                    lineHeight: '30px',
                },
            },
            [theme.breakpoints.down(701)]: {
                '& #subtitle': {
                    fontSize: '28.13px',
                    letterSpacing: '2px',
                },
                '& .header': {
                    fontSize: '19px',
                    letterSpacing: '0.34px',
                },
                '& #bottom': { marginBottom: '100px' },
            },
            [theme.breakpoints.down(650)]: {
                '& #subtitle': {
                    fontSize: '25px',
                    letterSpacing: '1.8px',
                },
                '& .header': {
                    fontSize: '16px',
                    letterSpacing: '0.5px',
                },
                '& #bottom': { marginBottom: '80px' },
            },
            [theme.breakpoints.down(501)]: {
                '& #subtitle': {
                    fontSize: '16.03px',
                    letterSpacing: '1.14px',
                },
                '& .header': {
                    fontSize: '10.86px',
                    letterSpacing: '0.45px',
                    lineHeight: '16px',
                },
                '& #bottom': { marginTop: 0 },
            },
        },
        comisionContainer: {
            maxHeight: '205px',
            marginBottom: '70px',
            [theme.breakpoints.down(950)]: {
                '& .blueComision': {
                    alignItems: 'flex-start',
                },
            },
        },
        comision: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: '10px',
            '& .comisionText': {
                fontSize: '18.33px',
                fontWeight: 500,
                color: '#3D4274',
                letterSpacing: '0.55px',
                marginBottom: '0',
                whiteSpace: 'nowrap',
                textOverflow: 'clip',
            },
            '& .endText': {
                fontSize: '18.33px',
                fontWeight: 500,
                color: '#3D4274',
                letterSpacing: '0.03px',
                marginBottom: '4.5px',
            },
            '& .greenText': {
                fontSize: '29.9px',
                fontWeight: 700,
                color: '#00A9A7',
                marginBottom: '0',
                marginRight: '10px',
                marginLeft: '10px',
            },
            '& .blueText': {
                fontSize: '29.9px',
                fontWeight: 700,
                color: '#3D4274',
                letterSpacing: '0.03px',
                marginBottom: '0',
                marginRight: '10px',
                marginLeft: '10px',
            },
            [theme.breakpoints.down(769)]: {
                '& .comisionText': { fontSize: '21px' },
                '& .endText': { fontSize: '21px' },
                '& .greenText': {
                    fontSize: '20px',
                },
                '& .blueText': {
                    fontSize: '20px',
                    marginLeft: '0',
                },
            },
            [theme.breakpoints.down(701)]: {
                '& .comisionText': {
                    fontSize: '19px',
                    letterSpacing: '0.36px',
                },
                '& .endText': {
                    fontSize: '19px',
                    letterSpacing: '0.69px',
                },
                '& .greenText': {
                    fontSize: '20px',
                },
                '& .blueText': {
                    fontSize: '20px',
                    marginLeft: '0',
                },
            },
            [theme.breakpoints.down(650)]: {
                '& .comisionText': {
                    fontSize: '16px',
                    letterSpacing: '0.49px',
                },
                '& .endText': {
                    fontSize: '16px',
                    letterSpacing: '0.69px',
                },
                '& .greenText': {
                    fontSize: '19px',
                    margin: '0 5px 0 2px',
                },
                '& .blueText': { fontSize: '19px' },
            },
            [theme.breakpoints.down(501)]: {
                '& .comisionText': {
                    fontSize: '10.86px',
                    letterSpacing: '0.4px',
                },
                '& .endText': {
                    fontSize: '10.86px',
                    letterSpacing: '0.5px',
                },
                '& .greenText': {
                    fontSize: '25px',
                },
                '& .blueText': { fontSize: '25px' },
            },
        },
        bottomText: {
            float: 'right',
            width: '38%',
            fontSize: '18.33px',
            fontWeight: 500,
            color: '#3D4274',
            letterSpacing: '0.75px',
            lineHeight: '26.35px',
            '& .boldText': { fontWeight: 900 },
            [theme.breakpoints.down(1200)]: {
                float: 'none',
                width: '57.35%',
            },
            [theme.breakpoints.down(769)]: {
                fontSize: '21px',
                letterSpacing: '0.75px',
                lineHeight: '30px',
            },
            [theme.breakpoints.down(701)]: {
                fontSize: '19px',
                letterSpacing: '0.34px',
                lineHeight: '28px',
            },
            [theme.breakpoints.down(650)]: {
                width: '70%',
                fontSize: '16px',
                letterSpacing: '0.5px',
                lineHeight: '26px',
            },
            [theme.breakpoints.down(501)]: {
                width: '60%',
                fontSize: '10.86px',
                letterSpacing: '0.45px',
                lineHeight: '16px',
            },
        },
        subtext: {
            width: '100%',
            fontSize: '15px',
            fontWeight: 600,
            color: '#A2A5BD',
            letterSpacing: '1px',
            lineHeight: '25px',
            marginBottom: '70px',
            [theme.breakpoints.down(1200)]: {
                width: '100%',
            },
            [theme.breakpoints.down(769)]: {
                fontSize: '21px',
                letterSpacing: '0.75px',
                lineHeight: '30px',
            },
            [theme.breakpoints.down(701)]: {
                width: '84%',
                fontSize: '19px',
                letterSpacing: '0.34px',
                lineHeight: '28px',
            },
            [theme.breakpoints.down(650)]: {
                width: '90%',
                fontSize: '16px',
                letterSpacing: '0.5px',
                lineHeight: '26px',
            },
            [theme.breakpoints.down(501)]: {
                fontSize: '10.86px',
                letterSpacing: '0.45px',
                lineHeight: '16px',
            },
        },
        blueContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-end',
            paddingBottom: '15px',
            [theme.breakpoints.down(950)]: {
                flexDirection: 'column',
                alignItems: 'flex-start',
                marginLeft: '5px',
            },
        },
        rightNumber: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            [theme.breakpoints.down(900)]: { marginLeft: '5px' },
            [theme.breakpoints.down(510)]: { marginLeft: '25px' },
            [theme.breakpoints.down(350)]: { marginLeft: '6px' },
        },
    });
