// React imports
import React from 'react';

// UI – style imports
import { WithStyles, withStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';

// local imports
import { styles } from './PressCarousel.style';
import ceo from './images/el-ceo.png';
import cnn from './images/cnn.png';
import contxto from './images/contxto.png';
import efe from './images/efe.png';
import entrepreneur from './images/entrepreneur.png';
import financiero from './images/el-financiero.png';
import fractal from './images/fractal.png';
import me from './images/me.png';

type PressCarousel = {
    type: string;
    style: object;
} & typeof defaultProps;

const defaultProps = {
    type: 'cnn',
};

const DATA_SOURCE = {
    cnn: [
        cnn,
        `"La empresa 'fintech' Cuenca junto con\
         los artesanos de bordado de Tenango\
         de Doria en Hidalgo, México, se\
         unieron para así ofrecer una tarjeta\
         bancaria con un toque exclusivo..."
        `,
    ],
    financiero: [
        financiero,
        `"...un vigilante que no tiene tiempo para\
         ir a una sucursal y abrir una cuenta,\
         entonces puede pedir una tarjeta con\
         nosotros y llega a su casa"
        `,
    ],
    entrepreneur: [
        entrepreneur,
        `"Para 2017, el sueño de crear una\
         fintech, el amor y las condiciones del\
         mercado le señalaron a Matin que\
         México era el mejor lugar para empezar\
         desde cero. Así nació Cuenca."
        `,
    ],
    ceo: [
        ceo,
        `"Los usuarios quieren un servicio... para\
         llevar a cabo envío y recepción de\
         pagos de manera inmediata, segura y\
         efectiva, con la facilidad de poder\
         abrir una cuenta con solo una aplicación y\
         una identificación oficial."
        `,
    ],
    efe: [
        efe,
        `"Para nosotros, eso es muy importante.\
         Este nivel de empatía, este nivel de\
         cultura de ser humano, de entender\
         que Cuenca es para todos, no para el 5\
         % (más privilegiado) de los mexicanos;\
         en realidad es para todos"
        `,
    ],
    fractal: [
        fractal,
        `"...Cuenca en alianza con el\
         Campamento Tortuguero Palmaritos\
         utilizarán drones y software de código\
         abierto para monitorear y analizar a 3\
         especies de tortuga en Oaxaca."
        `,
    ],
    contxto: [
        contxto,
        `"Hicimos posible obtener una tarjeta\
         virtual inmediatamente después de\
         terminar el proceso de registro, con\
         ella nuestros clientes pueden pagar\
         servicios de streaming, comida a\
         domicilio, servicios online, etc."
        `,
    ],
    me: [
        me,
        `"Cuenca y la ilustradora Carla\
         Escareño se unieron en\
         colaboración para generar una\
         tarjeta de edición especial."
        `,
    ],
};

function PressCarousel(props: PressCarousel & WithStyles<typeof styles>) {
    const { classes, type, ...rest } = props;
    const data = DATA_SOURCE[type];

    return (
        <Box className={classes.root} {...rest}>
            <p className={classes.quote}>{data[1]}</p>

            <Box className={classes.logo}>
                <img src={data[0]} alt={`${type} logo PNG`} />
            </Box>
        </Box>
    );
}
PressCarousel.defaultProps = defaultProps;

export default withStyles(styles)(PressCarousel);
