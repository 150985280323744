import { Theme, createStyles } from '@material-ui/core/styles';

export const styles = (theme: Theme) =>
    createStyles({
        root: {
            fontFamily: 'MuseoSans, sans-serif',
            marginBottom: '363px',
            position: 'relative',
            [theme.breakpoints.up(1441)]: { height: '550px' },
            [theme.breakpoints.up(1700)]: { height: '750px' },
            [theme.breakpoints.up(2300)]: { height: '1000px' },
            [theme.breakpoints.down(1100)]: { height: '1450px' },
            [theme.breakpoints.down(850)]: {
                height: '1100px',
                marginBottom: '510px',
            },
            [theme.breakpoints.down(600)]: { marginBottom: '200px' },
            [theme.breakpoints.down(501)]: { height: '800px' },
        },
        gif: {
            position: 'absolute',
            height: '100%',
            width: '100%',
            [theme.breakpoints.up(1441)]: {
                '& img': {
                    width: '100%',
                },
            },
            [theme.breakpoints.down(1100)]: {
                height: '50%',
                top: '55%',
            },
            [theme.breakpoints.down(850)]: { left: 0 },
            [theme.breakpoints.down(550)]: { top: '50%' },
            [theme.breakpoints.down(450)]: { top: '40%' },
            [theme.breakpoints.down(400)]: { top: '60%' },
        },
        content: {
            width: '43.6%', // 627.84
            marginLeft: '28.4%',
            [theme.breakpoints.down(1100)]: {
                height: '50%',
                width: '51.43%',
                marginLeft: '20%',
            },
            [theme.breakpoints.down(850)]: {
                height: '45%',
                width: '70%',
                marginLeft: '15%',
            },
            [theme.breakpoints.down(501)]: {
                height: '30%',
                marginLeft: '22.28%',
            },
        },
        textContainer: {
            width: '56.1%', // 352px
            marginLeft: '25.25%',
            [theme.breakpoints.down(1200)]: {
                width: '70%',
                marginLeft: '15%',
            },
            [theme.breakpoints.down(1100)]: { width: '90%' },
            [theme.breakpoints.down(501)]: { marginLeft: 0 },
        },
        titleContainer: {
            marginBottom: '133px',
            '& #top': {
                fontSize: '32.07px',
                fontWeight: 500,
                color: '#3D4274',
                letterSpacing: '2px',
                marginBottom: '0',
            },
            '& .title': {
                fontSize: '59.8px',
                fontWeight: 900,
                color: '#3D4274',
                letterSpacing: '7px',
                marginBottom: '0',
            },
            [theme.breakpoints.down(1300)]: { marginBottom: '100px' },
            [theme.breakpoints.down(1000)]: {
                marginLeft: '20%',
                '& #top': {
                    fontSize: '29.7px',
                    letterSpacing: '2.11px',
                },
                '& .title': {
                    fontSize: '55.24px',
                    letterSpacing: '6.34px',
                },
            },
            [theme.breakpoints.down(701)]: {
                '& #top': {
                    fontSize: '28.13px',
                    letterSpacing: '2px',
                },
                '& .title': {
                    fontSize: '52.24px',
                    letterSpacing: '6px',
                },
            },
            [theme.breakpoints.down(650)]: {
                '& #top': {
                    fontSize: '25px',
                    letterSpacing: '1.8px',
                },
                '& .title': {
                    fontSize: '45px',
                    letterSpacing: '5.5px',
                },
            },
            [theme.breakpoints.down(501)]: {
                marginBottom: '50px',
                '& #top': {
                    fontSize: '16.07px',
                    letterSpacing: '1.14px',
                },
                '& .title': {
                    fontSize: '29.85px',
                    letterSpacing: '3.43px',
                },
            },
        },
        text: {
            width: '93%',
            fontSize: '18.33px',
            fontWeight: 500,
            color: '#3D4274',
            letterSpacing: '0.75px',
            lineHeight: '26.35px',
            [theme.breakpoints.down(769)]: {
                fontSize: '21px',
                letterSpacing: '0.9px',
                lineHeight: '30px',
            },
            [theme.breakpoints.down(701)]: {
                fontSize: '19px',
                letterSpacing: '0.34px',
                lineHeight: '28px',
            },
            [theme.breakpoints.down(650)]: {
                fontSize: '16px',
                letterSpacing: '0.5px',
                lineHeight: '26px',
            },
            [theme.breakpoints.down(501)]: {
                fontSize: '10.86px',
                letterSpacing: '0.45px',
                lineHeight: '16px',
            },
        },
    });
