// React imports
import React from 'react';

// UI – style imports
import { WithStyles, withStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';

// local imports
import { styles } from './Identity.style';
import ID from 'components/ID';

function Identity(props: WithStyles<typeof styles>) {
    const { classes } = props;

    return (
        <Box className={classes.root}>
            <Box className={classes.textContainer}>
                <Box id="titleContainer">
                    <p className="title">ES</p>
                    <p className="title" id="bot">
                        PARA
                    </p>
                    <p className="title" id="bot">
                        TODOS,
                    </p>
                    <p id="sub">abre tu cuenta</p>
                </Box>

                <p id="explanation">
                    Obtener una cuenta en Cuenca es muy sencillo: descarga la aplicación, regístrate y comienza vivir en
                    la era digital.
                    <br />
                    <br />
                    Si eres mayor de edad y necesitas una cuenta pero no tienes identificación oficial, ¡no te
                    preocupes! En Cuenca sólo necesitas tu CURP para abrir tu cuenta.
                    <br />
                    <br />
                    En Cuenca tenemos cuatro niveles de cuenta para cubrir todas las necesidades, desde aquellos que
                    hacen depósitos en efectivo, hasta aquellos que reciben transferencias concurrentes. ¡Tú escoges qué
                    tipo de cuenta quieres!
                </p>
            </Box>

            <Box id="idContainer">
                <Box className="smallMarginRight">
                    <ID type="ine" orientation="right" />
                </Box>
                <Box className="bigMarginRight">
                    <ID type="pasaporte" orientation="left" />
                </Box>
                <Box className="smallMarginRight">
                    <ID type="residencia" orientation="right" />
                </Box>
                <Box>
                    <ID type="matricula" orientation="left" />
                </Box>
            </Box>
        </Box>
    );
}

export default withStyles(styles)(Identity);
