// React imports
import React from 'react';

// UI - style imports
import { WithStyles, withStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';

// local imports
import { styles } from './Notice.style';

function Notice(props: WithStyles<typeof styles>) {
    const { classes } = props;

    return (
        <Box className={classes.root}>
            <hr />

            <p>
                El sábado 3 de agosto de 2024, a las 15:00 horas, experimentamos incidencias en la conexión con uno de
                nuestros proveedores, la cual tuvo una duración de aproximadamente 12 horas. A raíz de esto, tuvimos
                retrasos en las transferencias y podrían tardar en verse reflejadas; también estamos experimentando
                incidencias en los cargos a tus tarjetas. Estamos trabajando con nuestro proveedor para solucionar a la
                brevedad posible. Lamentamos los inconvenientes y agradecemos tu paciencia.
            </p>
        </Box>
    );
}

export default withStyles(styles)(Notice);
