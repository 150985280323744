import { Theme, createStyles } from '@material-ui/core';

export const styles = (theme: Theme) =>
    createStyles({
        root: {
            fontFamily: 'MuseoSans, sans-serif',
            marginBottom: '262px',
            clear: 'both',
            '& #secondSub': {
                marginLeft: '35%',
            },
            [theme.breakpoints.down(900)]: { marginBottom: '350px' },
            [theme.breakpoints.down(501)]: { marginBottom: '163px' },
        },
        virtual: {
            display: 'flex',
            flexDirection: 'row',
            minHeight: '714px',
            width: '56.46%',
            marginLeft: '28%',
            marginBottom: '130px',
            [theme.breakpoints.up(1441)]: {
                maxWidth: '813px',
                margin: '0 auto 130px auto',
            },
            [theme.breakpoints.down(1400)]: { marginLeft: '28%' },
            [theme.breakpoints.down(1000)]: {
                flexDirection: 'column',
                justifyContent: 'center',
                width: '56.12%',
                margin: 'auto',
                marginBottom: '100px',
            },
            [theme.breakpoints.down(501)]: {
                marginBottom: '40px',
                marginLeft: '20%',
            },
        },
        virtualTextContainer: {
            width: '46%',
            marginRight: '16.11%',
            [theme.breakpoints.down(1000)]: {
                width: '100%',
            },
        },
        titleContainer: {
            marginBottom: '128px',
            '& #snd': {
                marginLeft: '20px',
            },
            '& #trd': {
                marginBottom: '10px',
                marginLeft: '45px',
            },
            '& #bot': {
                fontSize: '32.2px',
                fontWeight: 500,
                color: '#3D4274',
                letterSpacing: '2px',
            },
            [theme.breakpoints.down(1000)]: {
                marginBottom: '72px',
                '& #bot': {
                    fontSize: '29.7px',
                    letterSpacing: '2.13px',
                },
            },
            [theme.breakpoints.down(701)]: {
                marginBottom: '95px',
                '& #bot': {
                    fontSize: '27.95px',
                    letterSpacing: '2px',
                },
            },
            [theme.breakpoints.down(501)]: {
                marginBottom: '68px',
                '& #bot': {
                    fontSize: '24px',
                    letterSpacing: '1.2px',
                },
            },
            [theme.breakpoints.down(400)]: {
                '& #bot': {
                    fontSize: '22.5px',
                    letterSpacing: '0.5px',
                },
            },
        },
        title: {
            fontSize: '59.57px',
            fontWeight: 900,
            color: ' #3D4274',
            letterSpacing: '7px',
            marginBottom: '0',
            [theme.breakpoints.down(1000)]: {
                fontSize: '55.24px',
                letterSpacing: '6.34px',
            },
            [theme.breakpoints.down(701)]: {
                fontSize: '52.24px',
                letterSpacing: '6px',
            },
            [theme.breakpoints.down(650)]: {
                fontSize: '45px',
                letterSpacing: '5.5px',
            },
            [theme.breakpoints.down(501)]: {
                fontSize: '29.85px',
                letterSpacing: '3.43px',
            },
        },
        subtitle: {
            fontSize: '32.07px',
            fontWeight: 500,
            color: '#3D4274',
            letterSpacing: '2px',
            marginBottom: '50px',
            maxWidth: '813px',
            [theme.breakpoints.down(1000)]: {
                fontSize: '29.7px',
                letterSpacing: '2.11px',
                marginBottom: '45px',
                marginLeft: '26.45%',
            },
            [theme.breakpoints.down(701)]: {
                fontSize: '28.13px',
                letterSpacing: '2px',
                marginBottom: '53px',
            },
            [theme.breakpoints.down(650)]: {
                fontSize: '25px',
                letterSpacing: '1.8px',
                marginLeft: '10%',
            },
            [theme.breakpoints.down(501)]: {
                fontSize: '16.07px',
                letterSpacing: '1.14px',
                marginBottom: '30px',
                marginLeft: '5%',
            },
        },
        text: {
            fontSize: '18.33px',
            fontWeight: 500,
            color: '#3D4274',
            letterSpacing: '0.75px',
            lineHeight: '26.35px',
            width: '307px',
            [theme.breakpoints.down(1000)]: {
                marginBottom: '100px',
                marginLeft: '26.45%',
            },
            [theme.breakpoints.down(769)]: {
                fontSize: '21px',
                letterSpacing: '0.97px',
                lineHeight: '30px',
            },
            [theme.breakpoints.down(701)]: {
                fontSize: '19px',
                letterSpacing: '0.34px',
                lineHeight: '28px',
                marginBottom: '53px',
            },
            [theme.breakpoints.down(650)]: {
                fontSize: '16px',
                letterSpacing: '0.5px',
                lineHeight: '26px',
                marginLeft: '10%',
            },
            [theme.breakpoints.down(501)]: {
                fontSize: '10.86px',
                letterSpacing: '0.42px',
                lineHeight: '16px',
                marginLeft: '5%',
                width: '100%',
            },
        },
        gif: {
            width: '39%',
            [theme.breakpoints.down(1000)]: {
                width: '50%',
                margin: 'auto',
            },
            [theme.breakpoints.down(900)]: {
                width: '85%',
            },
        },
        fork: {
            height: '349px',
            width: '100%',
            marginBottom: '85px',
            '& img': {
                height: '100%',
                width: '100%',
                objectFit: 'cover',
            },
            [theme.breakpoints.up(1300)]: { height: '400px' },
            [theme.breakpoints.up(1700)]: { height: '500px' },
            [theme.breakpoints.up(1880)]: { height: '600px' },
            [theme.breakpoints.down(900)]: {
                height: '300px',
                marginBottom: '56px',
            },
            [theme.breakpoints.down(501)]: {
                height: '250px',
                marginBottom: '30px',
                '& img': { objectFit: 'scale-down' },
            },
        },
        physical: {
            display: 'flex',
            flexDirection: 'row',
            height: '378px',
            width: '813px',
            marginLeft: '35%',
            '& #smol': {
                fontSize: '14.95px',
                fontWeight: 700,
                color: '#A2A5BD',
                letterSpacing: '1px',
                lineHeight: '23px',
                width: '303px',
                marginRight: '203px',
            },
            '& #asterisco': {
                marginTop: '26.35px',
                fontSize: '12px',
                fontWeight: 500,
                color: '#3D4274',
                letterSpacing: '0.15px',
                lineHeight: '26.35px',
                width: '307px',
            },
            [theme.breakpoints.up(1441)]: { margin: '0 auto 0 auto' },
            [theme.breakpoints.down(1400)]: {
                marginLeft: '22%',
                '& #smol': {
                    marginRight: '100px',
                },
            },
            [theme.breakpoints.down(1000)]: {
                flexDirection: 'column',
                width: '56.12%',
                margin: 'auto',
                '& #smol': {
                    fontSize: '16.95px',
                    letterSpacing: '1px',
                    lineHeight: '25px',
                    width: '100%',
                    marginBottom: '75px',
                },
                '& #physicalText': {
                    letterSpacing: '0.59px',
                    width: '100%',
                    marginLeft: '0',
                },
                '& #asterisco': {
                    fontSize: '13px',
                },
            },
            [theme.breakpoints.down(701)]: {
                '& #smol': {
                    fontSize: '14.95px',
                    letterSpacing: '1px',
                    lineHeight: '23px',
                },
            },
            [theme.breakpoints.down(650)]: {
                '& #smol': {
                    fontSize: '12.5px',
                    letterSpacing: '0.75px',
                    lineHeight: '21px',
                },
            },
            [theme.breakpoints.down(501)]: {
                width: '55.7%',
                '& #smol': {
                    fontSize: '8.54px',
                    letterSpacing: '0.57px',
                    lineHeight: '13.14px',
                    width: '100%',
                    marginBottom: '50px',
                },
                '& #asterisco': {
                    fontSize: '10px',
                },
            },
        },
        linkInsideP: {
            color: theme.palette.primary.dark,
            fontWeight: 700,
            textDecoration: 'none',
        },
    });
