// React imports
import React from 'react';

// UI – style imports
import { WithStyles, withStyles, useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';

// local imports
import { styles } from './Cards.style';
import virtualGif from 'images/gifs/app-steps-tarjeta-virtual.gif';

function Cards(props: WithStyles<typeof styles>) {
    const { classes } = props;

    const reverseResponsive = useMediaQuery('(min-width:1440px)');
    const secondTitleBP = useMediaQuery('(min-width:1000px)');
    const desktop = useMediaQuery('(min-width:769px)');
    const tablet = useMediaQuery('(min-width:701px)');

    return (
        <Box className={classes.root}>
            <Box className={classes.virtual}>
                <Box className={classes.virtualTextContainer}>
                    <Box className={classes.titleContainer}>
                        <p className={classes.title}>UNA</p>
                        <p id="snd" className={classes.title}>
                            SOLA
                        </p>
                        <p id="trd" className={classes.title}>
                            CUENTA,
                        </p>
                        <p id="bot">tus tarjetas</p>
                        <br />
                        <br />
                        <p className={classes.text}>
                            Nos aliamos con Tarjetas Cuenca, una sociedad constituida para darte nuevos servicios, para
                            ofrecerte una tarjeta física. Para más información visita
                            <br></br>
                            <a
                                className={classes.linkInsideP}
                                href="https://tarjetascuenca.com/travesia"
                                target="_blank"
                            >
                                https://tarjetascuenca.com/travesia
                            </a>
                        </p>
                    </Box>
                    <p className={classes.subtitle}>Tarjeta virtual</p>
                    <p className={classes.text}>
                        Al abrir tu cuenta podrás solicitar una tarjeta virtual con la cual puedes pagar todas tus
                        compras en línea: pide el súper, ve tu serie favorita, domicilia pagos o consiéntete con tu
                        postre favorito a domicilio.
                    </p>
                </Box>
                <Box className={classes.gif}>
                    <img src={virtualGif} alt="Pasos para ver tarjeta virtual" />
                </Box>
            </Box>
        </Box>
    );
}

export default withStyles(styles)(Cards);
