// React imports
import React from 'react';

// UI – style imports
import clsx from 'clsx';
import { WithStyles, withStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';

// local imports
import { styles } from './ID.style';
//import ine from './images/ine.png';
import ine from './images/ine.svg';
import matricula from './images/matricula-consular.svg';
import pasaporte from './images/pasaporte.svg';
import residencia from './images/tarjeta-residencia.svg';

type IDProps = {
    type: string;
    className?: string;
    orientation?: 'left' | 'right';
};

function ID(props: IDProps & WithStyles<typeof styles>) {
    const { classes, className, type, orientation } = props;

    let IDinfo: [string, string];
    switch (type) {
        case 'ine':
            IDinfo = [ine, 'INE'];
            break;

        case 'pasaporte':
            IDinfo = [pasaporte, 'PASAPORTE MEXICANO'];
            break;

        case 'residencia':
            IDinfo = [residencia, 'TARJETA DE RESIDENCIA'];
            break;

        case 'matricula':
            IDinfo = [matricula, 'MATRÍCULA CONSULAR'];
            break;

        default:
            return;
    }

    return (
        <Box className={clsx(classes.root, className)}>
            <Box id="idImg">
                <img src={IDinfo[0]} alt={`Documento ${IDinfo[1]}`} />
            </Box>

            <p id={orientation}>{IDinfo[1]}</p>
        </Box>
    );
}

export default withStyles(styles)(ID);
